// @flow
/*
 * Team edit duck with action creators and reducers
 * The flow in team edit is a little bit complicated:
 * 1. First we load view restrictions
 * 2. Then, depending on restrictions loaded, we load other data
 *
 **/
import {fromJS, List, Map, Set} from "immutable";
import {all, call, fork, put, select, takeEvery, takeLatest} from "redux-saga/effects";
import {push} from "connected-react-router/immutable";

import action from "common/utils/flux";
import * as snackbar from "containers/Snackbar/duck";
import {getEligibility} from "containers/EligibilityModifier/duck";
import * as api from "./api";
import * as s from "./selectors";
import {errorMessage} from "common/utils/error";
import type { Action } from "common/utils/flux";

// Action types
export const Actions = {
  GET_RESTRICTIONS: "icpc/team/GET_RESTRICTIONS",
  GET_RESTRICTIONS_SUCCESS: "icpc/team/GET_RESTRICTIONS_SUCCESS",
  GET_RESTRICTIONS_ERROR: "icpc/team/GET_RESTRICTIONS_ERROR",
  GET_TEAMDTO: "icpc/team/GET_TEAMDTO",
  GET_TEAMDTO_SUCCESS: "icpc/team/GET_TEAMDTO_SUCCESS",
  GET_TEAMDTO_ERROR: "icpc/team/GET_TEAMDTO_ERROR",
  GET_SITE_DETAILS_DTO_SUCCESS: "icpc/team/GET_SITE_DETAILS_DTO_SUCCESS",
  GET_TEAM_MEMBER_DTOS: "icpc/team/GET_TEAM_MEMBER_DTOS",
  GET_TEAM_MEMBER_DTOS_SUCCESS: "icpc/team/GET_TEAM_MEMBER_DTOS_SUCCESS",
  GET_TEAM_MEMBER_DTOS_ERROR: "icpc/team/GET_TEAM_MEMBER_DTOS_ERROR",
  GET_OTHER_SITES: "icpc/team/GET_OTHER_SITES",
  GET_OTHER_SITES_SUCCESS: "icpc/team/GET_OTHER_SITES_SUCCESS",
  GET_OTHER_SITES_ERROR: "icpc/team/GET_OTHER_SITES_ERROR",
  UPDATE_GENERIC_FIELD: "icpc/team/UPDATE_GENERIC_FIELD",
  GET_GENERIC_FIELDS: "icpc/team/GET_GENERIC_FIELDS",
  GET_GENERIC_FIELDS_SUCCESS: "icpc/team/GET_GENERIC_FIELDS_SUCCESS",
  GET_GENERIC_FIELDS_ERROR: "icpc/team/GET_GENERIC_FIELDS_ERROR",
  POST_GENERIC_FIELDS: "icpc/team/POST_GENERIC_FIELDS",
  POST_GENERIC_FIELDS_SUCCESS: "icpc/team/POST_GENERIC_FIELDS_SUCCESS",
  POST_GENERIC_FIELDS_ERROR: "icpc/team/POST_GENERIC_FIELDS_ERROR",
  MOVE_TEAM: "icpc/team/MOVE_TEAM",
  MOVE_TEAM_SUCCESS: "icpc/team/MOVE_TEAM_SUCCESS",
  MOVE_TEAM_ERROR: "icpc/team/MOVE_TEAM_ERROR",
  UPDATE_TEAMDTO: "icpc/team/UPDATE_TEAMDTO",
  UPDATE_TEAMDTO_SUCCESS: "icpc/team/UPDATE_TEAMDTO_SUCCESS",
  UPDATE_TEAMDTO_ERROR: "icpc/team/UPDATE_TEAMDTO_ERROR",
  CHANGE_COACH: "icpc/team/CHANGE_COACH",
  CREATE_TEAMMEMBER: "icpc/team/CREATE_TEAMMEMBER",
  CREATE_TEAMMEMBER_SUCCESS: "icpc/team/CREATE_TEAMMEMBER_SUCCESS",
  CREATE_TEAMMEMBER_ERROR: "icpc/team/CREATE_TEAMMEMBER_ERROR",
  UPDATE_TEAMMEMBER: "icpc/team/UPDATE_TEAMMEMBER",
  UPDATE_TEAMMEMBER_SUCCESS: "icpc/team/UPDATE_TEAMMEMBER_SUCCESS",
  UPDATE_TEAMMEMBER_ERROR: "icpc/team/UPDATE_TEAMMEMBER_ERROR",
  DELETE_TEAMMEMBER: "icpc/team/DELETE_TEAMMEMBER",
  DELETE_TEAMMEMBER_SUCCESS: "icpc/team/DELETE_TEAMMEMBER_SUCCESS",
  DELETE_TEAMMEMBER_ERROR: "icpc/team/DELETE_TEAMMEMBER_ERROR",
  DELETE_TEAM: "icpc/team/DELETE_TEAM",
  DELETE_TEAM_SUCCESS: "icpc/team/DELETE_TEAM_SUCCESS",
  DELETE_TEAM_ERROR: "icpc/team/DELETE_TEAM_ERROR",
  CANCEL_TEAM: "icpc/team/CANCEL_TEAM",
  CANCEL_TEAM_SUCCESS: "icpc/team/CANCEL_TEAM_SUCCESS",
  CANCEL_TEAM_ERROR: "icpc/team/CANCEL_TEAM_ERROR",
  EDIT: "icpc/team/EDIT"
};

const defaultStore = Map({
  restrictions: Map(),
  teamDto: Map(),
  siteDetailsDto: Map(),
  teamMembers: List(),
  otherSites: List(),
  genericFields: List(),
  loading: Set(["restrictions", "teamDto"]),
  membersLoading: Set(),
  error: Set(),
  deleting: false,
  canceling: false,
  genericFieldsPosting: false
});

// Reducers
export default function reducer(
  state: Map<string, any> = defaultStore,
  action: Action
) {
  switch (action.type) {
    case Actions.GET_RESTRICTIONS:
      return state.merge({
        error: state.get("error").remove("restrictions"),
        loading: state.get("loading").add("restrictions")
      });
    case Actions.GET_RESTRICTIONS_SUCCESS:
      return state.merge({
        restrictions: fromJS(action.payload),
        loading: state.get("loading").remove("restrictions")
      });
    case Actions.GET_RESTRICTIONS_ERROR:
      return state.merge({
        error: state.get("error").add("restrictions"),
        loading: state.get("loading").remove("restrictions")
      });
    case Actions.GET_TEAMDTO:
      return state.merge({
        error: state.get("error").remove("teamDto"),
        loading: state.get("loading").add("teamDto")
      });
    case Actions.GET_TEAMDTO_SUCCESS:
      return state.merge({
        teamDto: fromJS(action.payload),
        loading: state.get("loading").remove("teamDto")
      });
    case Actions.GET_TEAMDTO_ERROR:
      return state.merge({
        error: state.get("error").add("teamDto"),
        loading: state.get("loading").remove("teamDto")
      });
    case Actions.GET_SITE_DETAILS_DTO_SUCCESS:
      return state.set("siteDetailsDto", fromJS(action.payload));
    case Actions.UPDATE_TEAMDTO:
      return state.merge({
        error: state.get("error").remove("teamDtoUpdate"),
        loading: state.get("loading").add("teamDtoUpdate")
      });
    case Actions.UPDATE_TEAMDTO_SUCCESS:
      return state.merge({
        teamDto: fromJS(action.payload),
        loading: state.get("loading").remove("teamDtoUpdate")
      });
    case Actions.UPDATE_TEAMDTO_ERROR:
      return state.merge({
        error: state.get("error").add("teamDtoUpdate"),
        loading: state.get("loading").remove("teamDtoUpdate")
      });
    case Actions.CREATE_TEAMMEMBER:
      return state.merge({
        error: state.get("error").remove("membercreate"),
        loading: state.get("loading").add("membercreate")
      });
    case Actions.CREATE_TEAMMEMBER_SUCCESS: {
      const teamMembers: List<Map<string, any>> = state.get("teamMembers");
      return state.merge({
        loading: state.get("loading").remove("membercreate"),
        teamMembers: teamMembers.push(fromJS(action.payload))
      });
    }
    case Actions.CREATE_TEAMMEMBER_ERROR:
      return state.merge({
        error: state.get("error").add("membercreate"),
        loading: state.get("loading").remove("membercreate")
      });

    case Actions.CHANGE_COACH:
      return state.set(
        "membersLoading",
        state.get("membersLoading").add(action.meta.index)
      );
    case Actions.UPDATE_TEAMMEMBER:
      return state.set(
        "membersLoading",
        state.get("membersLoading").add(action.meta.index)
      );
    case Actions.UPDATE_TEAMMEMBER_SUCCESS:
      return state.merge({
        membersLoading: state.get("membersLoading").remove(action.meta.index),
        teamMembers: state
          .get("teamMembers")
          .set(action.meta.index, fromJS(action.payload))
      });
    case Actions.UPDATE_TEAMMEMBER_ERROR:
      return state.set(
        "membersLoading",
        state.get("membersLoading").remove(action.meta.index)
      );
    case Actions.DELETE_TEAMMEMBER:
      return state.set(
        "membersLoading",
        state.get("membersLoading").add(action.meta.index)
      );
    case Actions.DELETE_TEAMMEMBER_SUCCESS:
      return state.merge({
        membersLoading: Set(),
        teamMembers: state.get("teamMembers").delete(action.meta.index)
      });
    case Actions.DELETE_TEAMMEMBER_ERROR:
      return state.set(
        "membersLoading",
        state.get("membersLoading").remove(action.meta.index)
      );
    case Actions.UPDATE_GENERIC_FIELD: {
      const index = action.payload.index;
      const genericField = state.getIn(["genericFields", index]);
      if (genericField) {
        return state.setIn(
          ["genericFields", index],
          genericField.set("response", action.payload.response)
        );
      } else {
        return state;
      }
    }
    case Actions.GET_GENERIC_FIELDS: {
      // not interested in loaders
      return fromJS(state);
    }
    case Actions.GET_GENERIC_FIELDS_SUCCESS:
      return state.set("genericFields", fromJS(action.payload));
    case Actions.GET_GENERIC_FIELDS_ERROR: {
      // not interested in errors
      return state;
    }
    case Actions.POST_GENERIC_FIELDS: {
      return state.set("genericFieldsPosting", true);
    }
    case Actions.POST_GENERIC_FIELDS_SUCCESS:
      return state
        .set("genericFields", fromJS(action.payload))
        .set("genericFieldsPosting", false);
    case Actions.POST_GENERIC_FIELDS_ERROR: {
      return state.set("genericFieldsPosting", false);
    }
    case Actions.GET_OTHER_SITES: {
      // not interested in loaders
      return state;
    }
    case Actions.GET_OTHER_SITES_SUCCESS:
      return state.set("otherSites", fromJS(action.payload));
    case Actions.GET_OTHER_SITES_ERROR: {
      // not interested in errors
      return state;
    }
    case Actions.GET_TEAM_MEMBER_DTOS:
      return state.merge({
        error: state.get("error").remove("teamMembers"),
        loading: state.get("loading").add("teamMembers")
      });
    case Actions.GET_TEAM_MEMBER_DTOS_SUCCESS:
      return state.merge({
        teamMembers: fromJS(action.payload),
        loading: state.get("loading").remove("teamMembers")
      });
    case Actions.GET_TEAM_MEMBER_DTOS_ERROR:
      return state.merge({
        error: state.get("error").add("teamMembers"),
        loading: state.get("loading").remove("teamMembers")
      });
    case Actions.MOVE_TEAM:
      return state.merge({
        error: state.get("error").remove("teamMoving"),
        loading: state.get("loading").add("teamMoving")
      });
    case Actions.MOVE_TEAM_SUCCESS:
      return state
        .set("loading", state.get("loading").remove("teamMoving"))
        .mergeDeep({
          teamDto: { site: action.payload }
        });
    case Actions.MOVE_TEAM_ERROR:
      return state.merge({
        error: state.get("error").add("teamMoving"),
        loading: state.get("loading").remove("teamMoving")
      });
    case Actions.DELETE_TEAM:
      return state.set("deleting", true);
    case Actions.DELETE_TEAM_SUCCESS:
      return state.set("deleting", false);
    case Actions.DELETE_TEAM_ERROR:
      return state.set("deleting", false);
    case Actions.CANCEL_TEAM:
      return state.set("canceling", true);
    case Actions.CANCEL_TEAM_SUCCESS:
      return state.set("canceling", false);
    case Actions.CANCEL_TEAM_ERROR:
      return state.set("canceling", false);
    case Actions.EDIT:
      return state.mergeDeep(action.payload);
    default:
      return state;
  }
}

// Action creators
export function getRestrictions(teamId: number): Action {
  return action(Actions.GET_RESTRICTIONS, { teamId });
}
function getRestrictionsSuccess(payload: Object): Action {
  return action(Actions.GET_RESTRICTIONS_SUCCESS, payload);
}
function getRestrictionsError(err: Error): Action {
  return action(Actions.GET_RESTRICTIONS_ERROR, err);
}
export function getTeamDto(teamId: number): Action {
  return action(Actions.GET_TEAMDTO, { teamId });
}
function getTeamDtoSuccess(payload: Object): Action {
  return action(Actions.GET_TEAMDTO_SUCCESS, payload);
}
function getSiteDetailsDtoSuccess(payload: Object): Action {
  return action(Actions.GET_SITE_DETAILS_DTO_SUCCESS, payload);
}
function getTeamDtoError(err: Error): Action {
  return action(Actions.GET_TEAMDTO_ERROR, err);
}
export function getTeamMembers(teamId: number): Action {
  return action(Actions.GET_TEAM_MEMBER_DTOS, { teamId });
}
function getTeamMembersSuccess(payload: Object): Action {
  return action(Actions.GET_TEAM_MEMBER_DTOS_SUCCESS, payload);
}
function getTeamMembersError(err: Error): Action {
  return action(Actions.GET_TEAM_MEMBER_DTOS_ERROR, err);
}
export function getOtherSites(teamId: number): Action {
  return action(Actions.GET_OTHER_SITES, { teamId });
}
function getOtherSitesSuccess(payload: Object): Action {
  return action(Actions.GET_OTHER_SITES_SUCCESS, payload);
}
function getOtherSitesError(err: Error): Action {
  return action(Actions.GET_OTHER_SITES_ERROR, err);
}
export function getGenericFields(teamId: number): Action {
  return action(Actions.GET_GENERIC_FIELDS, { teamId });
}
function getGenericFieldsSuccess(payload: Object): Action {
  return action(Actions.GET_GENERIC_FIELDS_SUCCESS, payload);
}
function getGenericFieldsError(err: Error): Action {
  return action(Actions.GET_GENERIC_FIELDS_ERROR, err);
}
export function postGenericFields(): Action {
  return action(Actions.POST_GENERIC_FIELDS);
}
function postGenericFieldsSuccess(payload: Object): Action {
  return action(Actions.POST_GENERIC_FIELDS_SUCCESS, payload);
}
function postGenericFieldsError(err: Error): Action {
  return action(Actions.POST_GENERIC_FIELDS_ERROR, err);
}
export function updateGenericField(index: number, response: string) {
  return action(Actions.UPDATE_GENERIC_FIELD, { index, response });
}
export function moveTeam(teamId: number, siteId: number): Action {
  return action(Actions.MOVE_TEAM, { teamId, siteId });
}
function moveTeamSuccess(payload: Object): Action {
  return action(Actions.MOVE_TEAM_SUCCESS, payload);
}
function moveTeamError(err: Error): Action {
  return action(Actions.MOVE_TEAM_ERROR, err);
}
export function updateTeam(teamDto: Object): Action {
  return action(Actions.UPDATE_TEAMDTO, teamDto);
}
function updateTeamSuccess(payload: Object): Action {
  return action(Actions.UPDATE_TEAMDTO_SUCCESS, payload);
}
function updateTeamError(err: Error): Action {
  return action(Actions.UPDATE_TEAMDTO_ERROR, err);
}
export function changeCoach(index: number, coach: Object): Action {
  return action(Actions.CHANGE_COACH, coach, { index });
}
export function updateTeamMember(
  index: number,
  newTeamMember: Map<string, any>
): Action {
  return action(Actions.UPDATE_TEAMMEMBER, fromJS(newTeamMember), { index });
}
function updateTeamMemberSuccess(index: number, payload: Object): Action {
  return action(Actions.UPDATE_TEAMMEMBER_SUCCESS, payload, { index });
}
function updateTeamMemberError(index: number, err: Error): Action {
  return action(Actions.UPDATE_TEAMMEMBER_ERROR, err, { index });
}
export function deleteTeamMember(index: number, memberId: number): Action {
  return action(Actions.DELETE_TEAMMEMBER, memberId, { index });
}
function deleteTeamMemberSuccess(index: number): Action {
  return action(Actions.DELETE_TEAMMEMBER_SUCCESS, null, { index });
}
function deleteTeamMemberError(index: number, err: Error): Action {
  return action(Actions.DELETE_TEAMMEMBER_ERROR, err, { index });
}
export function deleteTeam(teamId: number): Action {
  return action(Actions.DELETE_TEAM, teamId);
}
export function deleteTeamSuccess(): Action {
  return action(Actions.DELETE_TEAM_SUCCESS);
}
export function deleteTeamError(): Action {
  return action(Actions.DELETE_TEAM_ERROR);
}
export function cancelTeam(teamId: number): Action {
  console.log("creating action");
  return action(Actions.CANCEL_TEAM, teamId);
}
export function cancelTeamSuccess(): Action {
  return action(Actions.CANCEL_TEAM_SUCCESS);
}
export function cancelTeamError(): Action {
  return action(Actions.CANCEL_TEAM_ERROR);
}
export function createTeamMember(teamId: number, member: Object): Action {
  return action(Actions.CREATE_TEAMMEMBER, { teamId, member });
}
function createTeamMemberSuccess(payload: Object): Action {
  return action(Actions.CREATE_TEAMMEMBER_SUCCESS, payload);
}
function createTeamMemberError(err: Error): Action {
  return action(Actions.CREATE_TEAMMEMBER_ERROR, err);
}
export function edit(newStore: Object): Action {
  return action(Actions.EDIT, newStore);
}

// Side effects
function* fetchRestrictions(action: Action) {
  try {
    const payload = yield call(api.fetchRestrictions, action.payload.teamId);
    yield put(getRestrictionsSuccess(payload.data));
  } catch (err) {
    yield put(getRestrictionsError(err));
    yield put(snackbar.showMessage(errorMessage(err)));
  }
}

function* fetchTeamDto(action: Action) {
  try {
    const payload = yield call(api.fetchTeamDto, action.payload.teamId);
    yield put(getTeamDtoSuccess(payload.data));
  } catch (err) {
    yield put(push("/private"));
    yield put(getTeamDtoError(err));
    yield put(snackbar.showMessage(errorMessage(err)));
  }
}

function* fetchSiteDetailsDto(action: Action) {
  try {
    const currentSite = yield select(s.getCurrentSite);
    const payload = yield call(api.fetchSiteDetailsDto, currentSite.id);
    yield put(getSiteDetailsDtoSuccess(payload.data));
  } catch (err) {
    yield put(snackbar.showMessage(errorMessage(err)));
  }
}

function* fetchOtherSites(action: Action) {
  try {
    const payload = yield call(api.fetchSites, action.payload.teamId);
    yield put(getOtherSitesSuccess(payload.data));
  } catch (err) {
    yield put(getOtherSitesError(err));
  }
}

function* fetchTeamMembers(action: Action) {
  try {
    const payload = yield call(api.fetchTeamMembers, action.payload.teamId);
    yield put(getTeamMembersSuccess(payload.data));
  } catch (err) {
    yield put(getTeamMembersError(err));
    yield put(snackbar.showMessage(errorMessage(err)));
  }
}

function* fetchGenericFields(action: Action) {
  try {
    const payload = yield call(api.fetchGenericFields, action.payload.teamId);
    yield put(getGenericFieldsSuccess(payload.data));
  } catch (err) {
    yield put(getGenericFieldsError(err));
  }
}

function* putMoveTeam(action: Action) {
  try {
    const currentSite = yield select(s.getCurrentSite);
    const payload = yield call(
      api.moveTeam,
      action.payload.teamId,
      action.payload.siteId
    );
    yield put(
      snackbar.showMessage(`Team moved to ${payload.data.name}`, {
        actionText: "undo",
        action: moveTeam(action.payload.teamId, currentSite)
      })
    );
    yield put(moveTeamSuccess(payload.data));
    // refresh eligibility
    yield put(getEligibility(action.payload.teamId));
  } catch (err) {
    yield put(moveTeamError(err));
    yield put(snackbar.showMessage(errorMessage(err)));
  }
}

function* postTeam(action: Action) {
  try {
    const payload = yield call(api.updateTeam, 
      action.payload.id, 
      action.payload);
    yield put(snackbar.showMessage("Team updated"));
    yield put(updateTeamSuccess(payload.data));
    // refresh eligibility
    const teamDto = yield select(s.getTeamDto);
    yield put(getEligibility(teamDto.id));
  } catch (err) {
    yield put(updateTeamError(err));
    yield put(snackbar.showErrorMessage(err));
  }
}

function* postTeamMember(action: Action) {
  try {
    const payload = yield call(api.updateTeamMember, action.payload.get("memberId"),
        action.payload);
    yield put(snackbar.showMessage(`Team member ${payload.data.name} updated`));
    yield put(updateTeamMemberSuccess(action.meta.index, payload.data));
    // refresh eligibility
    const teamDto = yield select(s.getTeamDto);
    yield put(getEligibility(teamDto.id));
  } catch (err) {
    yield put(updateTeamMemberError(action.meta.index, err));
    yield put(snackbar.showErrorMessage(err));
  }
}

function* postCoach(action: Action) {
  try {
    const teamDto = yield select(s.getTeamDto);
    const payload = yield call(api.changeCoach, teamDto.id, action.payload);
    yield put(snackbar.showMessage(`Coach changed`));
    yield put(updateTeamMemberSuccess(action.meta.index, payload.data));
    // refresh eligibility
    yield put(getEligibility(teamDto.id));
  } catch (err) {
    yield put(updateTeamMemberError(action.meta.index, err));
    yield put(snackbar.showMessage(errorMessage(err)));
  }
}

function* postGenericFieldsSaga(action: Action) {
  try {
    const genericFields = yield select(s.getGenericFields);
    const teamDto = yield select(s.getTeamDto);
    const payload = yield call(
      api.postGenericFields,
      teamDto.id,
      genericFields.toJS()
    );
    yield put(snackbar.showMessage(`Contest-specific fields updated`));
    yield put(postGenericFieldsSuccess(payload.data));
    // refresh eligibility
    yield put(getEligibility(teamDto.id));
  } catch (err) {
    yield put(postGenericFieldsError(err));
    yield put(snackbar.showMessage(errorMessage(err)));
  }
}

function* removeTeamMember(action: Action) {
  try {
    yield call(api.deleteTeamMember, action.payload);
    yield put(snackbar.showMessage(`Team member deleted from the team`));
    yield put(deleteTeamMemberSuccess(action.meta.index));
    // refresh eligibility
    const teamDto = yield select(s.getTeamDto);
    yield put(getEligibility(teamDto.id));
  } catch (err) {
    yield put(deleteTeamMemberError(action.meta.index, err));
    yield put(snackbar.showErrorMessage(err));
  }
}

function* removeTeam(action: Action) {
  try {
    yield call(api.deleteTeam, action.payload);
    yield put(snackbar.showMessage(`Team deleted`));
    yield put(deleteTeamSuccess());
    yield put(push("/private"));
  } catch (err) {
    yield put(deleteTeamError());
    yield put(snackbar.showErrorMessage(err));
  }
}

function* selfCancelTeam(action: Action) {
  console.log("cancel team action: " + action.payload);
  try {
    yield call(api.cancelTeam, action.payload);
    yield put(snackbar.showMessage(`Team cancelled`));
    yield put(cancelTeamSuccess());
  } catch (err) {
    yield put(cancelTeamError());
    yield put(snackbar.showErrorMessage(err));
  }
}

function* createNewTeamMember(action: Action) {
  try {
    const payload = yield call(api.createTeamMember, action.payload.teamId, [
      action.payload.member
    ]);
    yield put(snackbar.showMessage(`Team member added to the team`));
    // server sends an array
    yield put(createTeamMemberSuccess(payload.data.pop()));
    // refresh eligibility
    yield put(getEligibility(action.payload.teamId));
  } catch (err) {
    yield put(createTeamMemberError(err));
    yield put(snackbar.showErrorMessage(err));
  }
}

function* watchGetRestrictions(): Generator<Function, void, void> {
  yield takeLatest(Actions.GET_RESTRICTIONS, fetchRestrictions);
}
function* watchGetTeamDto(): Generator<Function, void, void> {
  yield takeLatest(Actions.GET_TEAMDTO, fetchTeamDto);
}
function* watchGetTeamDtoSuccess(): Generator<Function, void, void> {
  yield takeLatest(Actions.GET_TEAMDTO_SUCCESS, fetchSiteDetailsDto);
}
function* watchGetOtherSites(): Generator<Function, void, void> {
  yield takeLatest(Actions.GET_OTHER_SITES, fetchOtherSites);
}
function* watchGetTeamMembers(): Generator<Function, void, void> {
  yield takeLatest(Actions.GET_TEAM_MEMBER_DTOS, fetchTeamMembers);
}
function* watchGetGenericFields(): Generator<Function, void, void> {
  yield takeLatest(Actions.GET_GENERIC_FIELDS, fetchGenericFields);
}
function* watchMoveTeam(): Generator<Function, void, void> {
  yield takeLatest(Actions.MOVE_TEAM, putMoveTeam);
}
function* watchMoveTeamSuccess(): Generator<Function, void, void> {
  yield takeLatest(Actions.MOVE_TEAM_SUCCESS, fetchSiteDetailsDto);
}
function* watchUpdateTeamDto(): Generator<Function, void, void> {
  yield takeLatest(Actions.UPDATE_TEAMDTO, postTeam);
}
function* watchUpdateTeamMemberDto(): Generator<Function, void, void> {
  yield takeEvery(Actions.UPDATE_TEAMMEMBER, postTeamMember);
}
function* watchChangeCoach(): Generator<Function, void, void> {
  yield takeEvery(Actions.CHANGE_COACH, postCoach);
}
function* watchDeleteTeamMember(): Generator<Function, void, void> {
  yield takeEvery(Actions.DELETE_TEAMMEMBER, removeTeamMember);
}
function* watchDeleteTeam(): Generator<Function, void, void> {
  yield takeLatest(Actions.DELETE_TEAM, removeTeam);
}
function* watchCancelTeam(): Generator<Function, void, void> {
  yield takeLatest(Actions.CANCEL_TEAM, selfCancelTeam);
}
function* watchCreateTeamMember(): Generator<Function, void, void> {
  yield takeEvery(Actions.CREATE_TEAMMEMBER, createNewTeamMember);
}
function* watchPostGenericFields(): Generator<Function, void, void> {
  yield takeEvery(Actions.POST_GENERIC_FIELDS, postGenericFieldsSaga);
}

export function* teamSagas(): Generator<Function, void, void> {
  yield all([
    fork(watchGetRestrictions),
    fork(watchGetTeamDto),
    fork(watchGetTeamDtoSuccess),
    fork(watchUpdateTeamDto),
    fork(watchUpdateTeamMemberDto),
    fork(watchDeleteTeamMember),
    fork(watchDeleteTeam),
    fork(watchCancelTeam),
    fork(watchCreateTeamMember),
    fork(watchChangeCoach),
    fork(watchGetOtherSites),
    fork(watchGetTeamMembers),
    fork(watchGetGenericFields),
    fork(watchMoveTeam),
    fork(watchMoveTeamSuccess),
    fork(watchPostGenericFields)
  ]);
}
