// @flow

/*
 * Security duck with action creators and reducers
 * see https://github.com/erikras/ducks-modular-redux for pattern guide
 *
 **/
import { errorMessage } from "common/utils/error";
import action from "common/utils/flux";
import { fromJS, Map } from "immutable";

import type { SnackbarAction, SnackbarConfig } from "./types";

const defaultStore = fromJS({
  open: false,
  autoHideDuration: 15000,
  message: "",
  horizontal: "left",
  vertical: "bottom",
  action: null,
  actionText: "undo",
  variant: "success"
});

// Action types
export const Actions = {
  MESSAGE_SHOW: "icpc/snackbar/MESSAGE_SHOW",
  MESSAGE_HIDE: "icpc/snackbar/MESSAGE_HIDE"
};

// Reducers
export default function reducer(
  state: Map<string, any> = defaultStore,
  action: SnackbarAction
) {
  switch (action.type) {
    case Actions.MESSAGE_SHOW:
      return state.merge(action.payload);
    case Actions.MESSAGE_HIDE:
      return state.merge(action.payload);
    default:
      return state;
  }
}

// Action creators
export function showMessage(
  message: string,
  config: SnackbarConfig = {}
): SnackbarAction {
  return action(Actions.MESSAGE_SHOW, {
    open: true,
    message,
    autoHideDuration: config.permanent ? null : config.duration || 15000,
    action: config.action,
    actionText: config.actionText,
    variant: config.variant ? config.variant : "success"
  });
}

export function showErrorMessage(err: Error) {
  return showMessage(errorMessage(err), { variant: "error" });
}

export function showErrorMessageSimple(err: String) {
  return showMessage(err, { variant: "error" });
}

export function hideMessage(): SnackbarAction {
  return action(Actions.MESSAGE_HIDE, {
    open: false,
    message: "",
    autoHideDuration: 15000
  });
}
