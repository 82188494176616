// @flow
/**
 * Security selectors
 **/
import {createSelector} from "reselect";
import {List, Map, Set} from "immutable";
import type { TeamRegistrationDto } from "./types";

const getTeamRegistration = (state: Map<string, any>) => {
  return state.get("teamRegistration");
};

const getActiveStep = createSelector(
  getTeamRegistration,
  (teamRegistration: Map<string, any>) => {
    return teamRegistration.get("activeStep");
  }
);

const isCreating = createSelector(
  getTeamRegistration,
  (teamRegistration: Map<string, any>) => {
    return teamRegistration.get("isCreating");
  }
);

const getErrorMessage = createSelector(
  getTeamRegistration,
  (teamRegistration: Map<string, any>) => {
    return teamRegistration.get("errorMessage");
  }
);

const getStructure = createSelector(
  getTeamRegistration,
  (teamRegistration: Map<string, any>) => {
    return teamRegistration.get("structure");
  }
);

const getContestAbbr = createSelector(
  getTeamRegistration,
  (teamRegistration: Map<string, any>) => {
    return teamRegistration.get("contestAbbr");
  }
);

const getSiteId = createSelector(
  getTeamRegistration,
  (teamRegistration: Map<string, any>) => {
    return teamRegistration.get("siteId");
  }
);

const getIuaId = createSelector(
  getTeamRegistration,
  (teamRegistration: Map<string, any>) => {
    return teamRegistration.get("iuaId");
  }
);

const getNumberOfTeams = createSelector(
  getTeamRegistration,
  (teamRegistration: Map<string, any>) => {
    return teamRegistration.get("numberOfTeams");
  }
);

const getTeams = createSelector(
  getTeamRegistration,
  (teamRegistration: Map<string, any>) => {
    return teamRegistration.get("teams");
  }
);

const isStudentCoach = createSelector(
  getTeamRegistration,
  (teamRegistration: Map<string, any>) => {
    return teamRegistration.get("isStudentCoach");
  }
);

/**
 * Validator globally for all teams
 */
const getTeamMembersValidation = createSelector(
  getTeams,
  (teams: List<Map<string, any>>) => {
    // for team member validations
    const globalMessages = [];

    for (let team of teams) {
      const teamMessages = [];
      let idx = 0;

      for (let member of team.get("members")) {

        if(member !=null){
        const validationMessage = member.get("validationMessage");
        const username = member.getIn(["person", "username"]);
        if (validationMessage !== null) {
          teamMessages.push(
            `Member ${username ? username : idx}: ${validationMessage}`
          );
        }
        idx++;
      }
      }
      if (teamMessages.length > 0)
        globalMessages.push(
          `Team "${team.get("name")}": ${teamMessages.join(" ")}`
        );
    }
    return globalMessages;
  }
);

const isValid = createSelector(
  getNumberOfTeams,
  getTeams,
  getIuaId,
  (numberOfTeams: ?number, teams: List<Map<string, any>>, iuaId: number) => {
    // to be valid, we need number of teams,
    // institution picked and all team names must be filled in correctly
    if (!numberOfTeams || numberOfTeams < 1 || !iuaId) return false;
    let dups: Set<string> = Set();
    // check duplicate names
    for (let team of teams) dups = dups.add(team.get("name"));
    if (dups.size !== teams.size) return false;
    return (
      teams.filter(value => value.get("errorText") !== undefined).size === 0
    );
  }
);

const getStructureData = createSelector(
  getStructure,
  (structure: Map<string, any>) => {
    return structure.get("data");
  }
);

const isStructureLoading = createSelector(
  getStructure,
  (structure: Map<string, any>) => {
    return structure.get("loading");
  }
);

const isStructureError = createSelector(
  getStructure,
  (structure: Map<string, any>) => {
    return structure.get("error");
  }
);

/**
 * Carefully transform our store into array of Dtos so we send clean stuff to the server
 */
const getTeamRegistrationDtos: Array<TeamRegistrationDto> = createSelector(
  getSiteId,
  getIuaId,
  getTeams,
  isStudentCoach,
  (
    siteId: number,
    institutionUnitId: number,
    teams: List<Map<string, any>>,
    studentCoach: boolean
  ) => {
    const dtos = [];
    for (let team of teams) {
      const members = team.get("members");
      const dto = {
        name: team.get("name"),
        siteId,
        institutionUnitId,
        studentCoach,
        teamMembers: members.map(member => {
          return {
            role: member.get("role"),
            person: member.getIn(["person", "id"]),
            badgeRole: member.get("badgeRole", null),
            certificateRole: member.get("certificateRole", null)
          };
        })
      };
      dtos.push(dto);
    }
    return dtos;
  }
);

export {
  getActiveStep,
  getContestAbbr,
  isStudentCoach,
  getSiteId,
  getTeamRegistration,
  getIuaId,
  getNumberOfTeams,
  getTeams,
  isValid,
  getStructureData,
  isStructureError,
  isStructureLoading,
  getTeamMembersValidation,
  getTeamRegistrationDtos,
  getErrorMessage,
  isCreating
};
