/**
 * @flow
 * Record defining UserRoles. See SecurityService.java to get idea.
 */

import type { RecordFactory } from "immutable";
import {Record} from "immutable";

type UserRolesDto = {
  admin: boolean,
  siteManager: boolean,
  sponsor: boolean,
  registered: boolean
};
// we want this record to be pesimistic -> set all roles to false by default
// Rob: registered must be true by default otherwise a person might be redirected to Finish Reg. Form when not needed
const newUserRoles: RecordFactory<UserRolesDto> = Record({
  admin: false,
  siteManager: false,
  sponsor: false,
  registered: true
});

class UserRoles extends newUserRoles {
  /**
   * Is current user admin?
   */
  isAdmin(): boolean {
    return this.get("admin");
  }

  /**
   * Is current user a manager of some site?
   */
  isASiteManager(): boolean {
    return this.isAdmin() || this.get("siteManager");
  }

  isGroupLeader(): boolean {
    return this.isAdmin() || this.get("groupLeader");
  }

  /**
   * Is current user a sponsor?
   */
  isSponsor(): boolean {
    return this.isAdmin() || this.get("sponsor");
  }

  /**
   * SiteManagers and Sponsors can see contests
   */
  canSeeContest() {
    return this.isASiteManager() || this.isSponsor();
  }

  /**
   * Is user registered
   */
  isRegistered(): boolean {
    return this.get("registered");
  }

  /**
   * Useful when user finishes registration so app stops redirecting to Registration Form
   * @param {boolean} reg
   */
  setRegistered(registered: boolean) {
    return this.set("registered", registered);
  }
}

export default UserRoles;
