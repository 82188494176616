/**
 * @flow
 * This file contains chunks of store to be persisted in local storage
 * This store is bootstraped in store.js file (store.subscribe), which is fired on every action dispatch
 *
 * Because we are using Immutable.js Map as our store, we need to convert it to native JS before persisting
 * DO NOT overdo this as we might lose performance benefits that Immutable.js provides (avoid using .toJS() selectors,
 * selecting primitives is the best way if it is possible)
 *
 * Use selectors to select stuff from store for maintain reasons
 */
import {Map} from "immutable";
import {createStructuredSelector} from "reselect";
import * as privateAppSelectors from "containers/PrivateApp/selectors";

export type StorageState = {
  isDrawerOpen: boolean
};

export const mapStoreToLocalStorage = (store: Map<string, any>) =>
  createStructuredSelector({
    isDrawerOpen: privateAppSelectors.isDrawerOpen
  });
