// @flow
/**
 * Person registration selectors
 **/
import {createSelector} from "reselect";
import {Map} from "immutable";

const getPersonRegistration = (state: Map<string, any>) => {
  return state.get("personRegistration");
};

const getProps = createSelector(
  getPersonRegistration,
  (personRegistration: Map<string, any>) => {
    return personRegistration.remove("genericFields").toJS();
  }
);

const getGenericFields = createSelector(
  getPersonRegistration,
  (personRegistration: Map<string, any>) => {
    return personRegistration.get("genericFields");
  }
);

export { getProps, getGenericFields };
