/**
 * @flow
 * Special function to find Array of keys in entire hierarchy by an item key (name)
 */
import {List, Map} from "immutable";

/**
 * Finds name indexes in Menu hierarchy. Returns them in Array.
 * Returns undefined if name not found.
 * 
 * @param {List} menuItems 
 * @param {string} name 
 */
export function findKeys(
  menuItems: List<Map<string, any>>,
  name: string
): ?Array<any> {
  if (!List.isList(menuItems)) return undefined;
  let retval = undefined;
  menuItems.forEach((val, idx) => {
    const k = findDeep(val, name, idx, []);
    if (Array.isArray(k)) {
      retval = k;
      // Stops the iteration
      return false;
    }
  });
  return retval;
}

function findDeep(
  menuItem: Map<string, any>,
  name: string,
  index: number,
  keys: Array<any> = []
): ?Array<any> {
  if (menuItem.get("name") === name) {
    keys.push(index);
    return keys;
  } else if (
    menuItem.get("children") !== undefined &&
    List.isList(menuItem.get("children"))
  ) {
    const children: List<Map<string, any>> = menuItem.get("children");
    keys.push(index);
    keys.push("children");
    let retval = undefined;
    children.forEach((val, idx) => {
      const k = findDeep(val, name, idx, keys);
      if (Array.isArray(k)) {
        retval = k;
        //stop the iteration
        return false;
      }
    });
    return retval;
  } else {
    return undefined;
  }
}
