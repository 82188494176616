/**
 * @flow
 * Contains default menu for admins.
 * You should delete some of these Items using action creators in duck.js
 * depending on the role of current user
 *
 * Do it just once (on user login/ user role fetch) as these action creators are pretty expensive.
 * On very drastic changes, it is smarter to replace entire hierarchy (calling replace)
 * rather than calling remove, insertAfter etc...
 *
 * TODO Or we can mainain set of menus for each role?
 */
import type { MenuItem } from "./types";

const defaultMenu: Array<MenuItem> = [
  {
    name: "dashboard",
    label: "Dashboard",
    children: [
      {
        name: "dashboard/dashboard",
        label: "Dashboard",
        to: "/private/dashboard"
      },
      {
        name: "dashboard/helpCenter",
        label: "Help center",
        to: "/private/help"
      }
      // {
      //   name: "dashboard/trackingMyRequests",
      //   label: "Tracking my requests",
      //   to: "/private/requests"
      // },
      // {
      //   name: "dashboard/managePendingRequests",
      //   label: "Manage pending requests",
      //   to: "/private/issues"
      // },
      // {
      //   name: "dashboard/helpTopicMgmnt",
      //   label: "Help topic management",
      //   to: "/private/topics"
      // }
    ]
  },
  {
    name: "teams",
    label: "Teams",
    children: [
      { name: "teams/myTeams", label: "My teams", to: "/private/myteams" },
      {
        name: "teams/create",
        label: "Create team(s)",
        to: "/private/teamRegistration"
      }
    ]
  },
  {
    name: "contests",
    label: "Contests",
    children: [
      {
        name: "contests/viewAll",
        label: "View all contests",
        to: "/private/contests"
      },
      {
        name: "contests/contestMaterials",
        label: "Contest materials",
        to: "/private/contestMaterials"
      }
    ]
  },
  // {
  //   name: "icpchotel",
  //   label: "Hotel",
  //   children: [
  //     {
  //       name: "icpchotel/hotel",
  //       label: "View Hotel",
  //       to: "/hotel/private/"
  //     }
  //   ]
  // },
  {
    name: "search",
    label: "Search",
    children: [
      // {
      //   name: "search/legacyAccounts",
      //   label: "Legacy accounts",
      //   to: "/private/persons/legacy"
      // },
      { name: "search/people", label: "People", to: "/private/persons" },
      {
        name: "search/countries",
        label: "Countries",
        to: "/private/countries"
      },
      {
        name: "search/institutions",
        label: "Institutions",
        to: "/private/institutions"
      },
      { name: "search/teams", label: "Teams", to: "/private/teams" }
    ]
  },
  {
    name: "other",
    label: "Other",
    children: [
      {
        name: "other/suggestedInstitutions",
        label: "Suggested institutions",
        to: "/private/institutions/suggest"
      },
      {
        name: "other/mergeAccounts",
        label: "Merge Accounts",
        to: "/private/mergeAccounts"
      }
    ]
  },
  {
    name: "version",
    label: "Version",
    children: [
      {
        name: "all/version",
        label: "All Versions",
        to: "/cms/newfeatures"
      }
    ]
  }
];

export default defaultMenu;
