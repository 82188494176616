import {applyMiddleware, compose, createStore} from "redux";
import {routerMiddleware} from "connected-react-router/immutable"
import createSagaMiddleware from "redux-saga";
import logger from "redux-logger";
import {fromJS, getIn} from "immutable";
import rootReducer from "./reducers";
import {mapStoreToLocalStorage} from "./storePersistent";
import {saveState} from "common/utils/localStorage";

const sagaMiddleware = createSagaMiddleware();

export default function configureStore(
  initialState: Object = {},
  history: Object
) {
  // Create the store with three middlewares
  // 1. sagaMiddleware: Library for handling side effects...
  // 2. routerMiddleware: Syncs the location/URL path to the store
  // 3. logger: Logs redux actions in console
  const middlewares = [routerMiddleware(history), sagaMiddleware, logger];

  const enhancers = [applyMiddleware(...middlewares)];

  const composeEnhancers =
    process.env.NODE_ENV !== "production" &&
    typeof window === "object" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      : compose;

  const store = createStore(
    rootReducer(history),
    fromJS(initialState),
    composeEnhancers(...enhancers)
  );
  // Extensions
  store.runSaga = sagaMiddleware.run;
  store.asyncReducers = {}; // Async reducer registry
  store.subscribe(() => {
    saveState(mapStoreToLocalStorage()(store.getState()));
  });
  return store;
}
