// @flow
import {fromJS} from "immutable";
import {combineReducers} from "redux-immutable";
// we import action here because we need to write our custom router reducer to work with Immutable map
import {LOCATION_CHANGE, connectRouter} from "connected-react-router/immutable";
// sub reducers
import security from "containers/Security/duck";
import forms from "containers/AspectFaces/duck";
import snackbar from "containers/Snackbar/duck";
import privateApp from "containers/PrivateApp/duck";
import publicApp from "containers/PublicApp/duck";
import teamRegistration from "routes/private/TeamRegistration/duck";
import team from "routes/private/Team/duck";
import personRegistration from "routes/private/PersonRegistration/duck";
import globals from "common/globals/duck";
import site from "routes/private/Site/duck";
import help from "routes/private/Help/duck";
import eligibilityModifier from "containers/EligibilityModifier/duck";
import xWiki from "routes/public/WikiPage/duck";
import certificate from "routes/private/ContestApp/PrintCenter/Certificate/duck";

/*
  Reducers
  Reducers match up the dispatched (fired) action with a function that should be called.
  It takes in a copy of state, modifies it, and returns the new state
  When state gets large, it makes sense to have multiple reducers that only deal with a piece of the state
*/

// Combine all our reducers together so they can handle different substates
// of our global state
// Our global state is Immutable.JS Map
/**
 * Creates the main reducer with the asynchronously loaded ones
 */
const rootReducer = (history) => combineReducers({
  router: connectRouter(history),
  forms: forms,
  security: security,
  snackbar: snackbar,
  privateApp: privateApp,
  publicApp: publicApp,
  teamRegistration: teamRegistration,
  team,
  personRegistration,
  globals,
  site,
  help,
  eligibilityModifier,
  xWiki,
  certificate
});

export default rootReducer;
