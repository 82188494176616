// @flow

/*
 * Globals duck with action creators and reducers
 * In this store is critical application info: icpcYear, regionalsYear and we also store WorldFinals contestId for conveniens
 * see https://github.com/erikras/ducks-modular-redux for pattern guide
 *
 **/
import { Map } from "immutable";
import { all, call, fork, put, select, takeLatest } from "redux-saga/effects";

import action from "common/utils/flux";

import { fetchGlobals } from "./api.js";
import * as s from "./selectors";
import type { Action } from "common/utils/flux";

// Action types
export const Actions = {
  GET_GLOBALS: "icpc/globals/GET_GLOBALS",
  GET_GLOBALS_SUCCESS: "icpc/globals/GET_GLOBALS_SUCCESS",
  GET_GLOBALS_ERROR: "icpc/globals/GET_GLOBALS_ERROR",
  SELECT_YEAR: "icpc/globals/SELECT_YEAR",
  SELECT_CONTEST: "icpc/globals/SELECT_CONTEST",
  SELECT_CONTEST_NAME: "icpc/globals/SELECT_CONTEST_NAME"


};
const defaultStore = Map({
  loading: false,
  error: false,
  selectedYear: 1997,
  currentContestId: 4298,
  currentContestName: ""
});
// Reducers
export default function reducer(
  state: Map<string, any> = defaultStore,
  action: Action
) {
  switch (action.type) {
    case Actions.GET_GLOBALS:
      return state.merge({ loading: true, error: false });
    case Actions.GET_GLOBALS_SUCCESS:
      return state.merge({
        loading: false,
        error: false,
        ...action.payload
      });
    case Actions.GET_GLOBALS_ERROR:
      return state.merge({ loading: false, error: true });
    case Actions.SELECT_YEAR:
      return state.set("selectedYear", action.payload);
    case Actions.CHANGE_VIDEO_STATUS:
      return state.set("videoStatus", action.payload);
    case Actions.SELECT_CONTEST:
      return state.set("currentContestId", action.payload);
    case Actions.SELECT_CONTEST_NAME:
      return state.set("currentContestName", action.payload);
    default:
      return state;
  }
}

// Action creators
export function loadGlobals(): Action {
  return action(Actions.GET_GLOBALS);
}

function loadGlobalsSuccess(payload: any): Action {
  return action(Actions.GET_GLOBALS_SUCCESS, payload);
}

function loadGlobalsError(err: Error): Action {
  return action(Actions.GET_GLOBALS_ERROR, err);
}

export function selectYear(year: number): Action {
  return action(Actions.SELECT_YEAR, year);
}

export function selectContest(id: number): Action {
  return action(Actions.SELECT_CONTEST, id);
}

export function selectContestName(name: string): Action {
  return action(Actions.SELECT_CONTEST_NAME, name);
}

export function changeVideoStatus(status: string): Action {
  return action(Actions.CHANGE_VIDEO_STATUS, status);
}

function* globals(action: Action): Generator<Function, void, void> {
  try {
    const payload = yield call(fetchGlobals);
    if (!payload) {
      yield put(loadGlobalsError(new Error("No data")));
    } else {
      yield put(loadGlobalsSuccess(payload.data));
      const year = yield select(s.getRegionalsYear);
      yield put(selectYear(parseInt(year, 10)));
    }
  } catch (err) {
    yield put(loadGlobalsError(err));
  }
}

function* watchGetGlobals(): Generator<Function, void, void> {
  yield takeLatest(Actions.GET_GLOBALS, globals);
}

export function* globalsSagas(): Generator<Function, void, void> {
  yield all([fork(watchGetGlobals)]);
}
