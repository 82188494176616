// @flow

/**
 * In JavaScript, everything is object. Here we want to check whether the input really is "{}"
 * @param {any} input
 */
export function isPlainObject(input: any) {
  return input && !Array.isArray(input) && typeof input === "object";
}

export function notEmpty(stuff: any) {
  return stuff && Array.isArray(stuff) && stuff.length > 0;
}

export function capitalize(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

// this function is useful because truthy checks won't render 0 in javascript
export function notEmptyString(value: any) {
  return value !== undefined && value !== null;
}

export function parseSearch(search: string) {
  if (!search || search.length < 4) {
    return {};
  }
  var search2 = search.substring(1);
  return JSON.parse(
    '{"' + search2.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
    function(key, value) {
      return key === "" ? value : decodeURIComponent(value);
    }
  );
}
