/*
 * @flow
 * Help duck
 *
 **/
import {push} from "connected-react-router/immutable";
import {fromJS, Map} from "immutable";

import action from "common/utils/flux";
import {all, call, fork, put, takeLatest} from "redux-saga/effects";
import {showErrorMessage, showMessage} from "containers/Snackbar/duck";
import * as api from "./api";
import type { Action } from "common/utils/flux";

const defaultStore = {
  loading: false
};

// Action types
export const Actions = {
  // async actions for fetching masters
  LOAD_ROOT: "private/help/LOAD_ROOT",
  LOAD_ROOT_SUCCESS: "private/help/LOAD_ROOT_SUCCESS",
  LOAD_ROOT_ERROR: "private/help/LOAD_ROOT_ERROR",
  // async actions for fetching topics
  LOAD_TOPIC: "private/help/LOAD_TOPIC",
  LOAD_TOPIC_SUCCESS: "private/help/LOAD_TOPIC_SUCCESS",
  LOAD_TOPIC_ERROR: "private/help/LOAD_TOPIC_ERROR",
  // async action for updating topics
  UPDATE_TOPIC: "private/help/UPDATE_TOPIC",
  UPDATE_TOPIC_SUCCESS: "private/help/UPDATE_TOPIC_SUCCESS",
  UPDATE_TOPIC_ERROR: "private/help/UPDATE_TOPIC_ERROR",
  // search topics
  SEARCH_TOPIC: "private/help/SEARCH_TOPIC",
  SEARCH_TOPIC_SUCCESS: "private/help/SEARCH_TOPIC_SUCCESS",
  SEARCH_TOPIC_ERROR: "private/help/SEARCH_TOPIC_ERROR",
  // ui-actions
  SET_TAB: "private/help/SET_TAB"
};

// Reducers
function rootReducer(
  state: Map<string, any> = fromJS(defaultStore),
  action: Action
) {
  switch (action.type) {
    case Actions.LOAD_ROOT:
      return state.set("loading", true);
    case Actions.LOAD_ROOT_SUCCESS:
      return state.merge({
        loading: false,
        dto: fromJS(action.payload)
      });
    case Actions.LOAD_ROOT_ERROR:
      return state.set("loading", false);
    default:
      return state;
  }
}

function topicReducer(state: Map<string, any> = Map(), action: Action) {
  switch (action.type) {
    case Actions.LOAD_TOPIC:
      return state.merge({
        [action.meta.key]: Map({
          loading: true
        })
      });
    case Actions.LOAD_TOPIC_SUCCESS:
      return state.merge({
        [action.meta.key]: Map({
          loading: false,
          dto: fromJS(action.payload)
        })
      });
    case Actions.LOAD_TOPIC_ERROR:
      return state.merge({
        [action.meta.key]: Map({
          loading: false
        })
      });
    case Actions.UPDATE_TOPIC:
      return state.merge({
        [action.meta.key]: Map({
          updating: true,
        })
      });
    case Actions.UPDATE_TOPIC_SUCCESS:

      return state.delete(action.meta.key).merge({
        [action.payload.key]: Map({
          updating: false,
          dto: fromJS(action.payload),
        })
      });
    case Actions.UPDATE_TOPIC_ERROR:
      return state.merge({
        [action.meta.key]: Map({
          updating: false,
        })
      });
    default:
      return state;
  }
}

function uiReducer(
  state: Map<string, any> = Map({ selectedTab: 0 }),
  action: Action
) {
  switch (action.type) {
    case Actions.SET_TAB:
      return state.set("selectedTab", action.payload);
    default:
      return state;
  }
}

function searchReducer(
  state: Map<string, any> = Map({ loading: false }),
  action: Action
) {
  switch (action.type) {
    case Actions.SEARCH_TOPIC:
      return state.set("loading", true);
    case Actions.SEARCH_TOPIC_SUCCESS:
      return state.merge({
        loading: false,
        results: fromJS(action.payload)
      });
    case Actions.SEARCH_TOPIC_ERROR:
      return state.set("loading", false);

    default:
      return state;
  }
}

export default function reducer(
  state: Map<string, any> = Map(),
  action: Action
) {
  return state.merge({
    root: rootReducer(state.get("root"), action),
    by_key: topicReducer(state.get("by_key"), action),
    ui_state: uiReducer(state.get("ui_state"), action),
    search_results: searchReducer(state.get("search_results"), action)
  });
}

// Action creators
export function fetchRoot(): Action {
  return action(Actions.LOAD_ROOT);
}
function fetchRootSuccess(payload: Object): Action {
  return action(Actions.LOAD_ROOT_SUCCESS, payload);
}
function fetchRootError(err: Error): Action {
  return action(Actions.LOAD_ROOT_ERROR, err);
}
export function fetchTopic(key: string): Action {
  return action(Actions.LOAD_TOPIC, null, { key });
}
function fetchTopicSuccess(key: string, payload: Object): Action {
  return action(Actions.LOAD_TOPIC_SUCCESS, payload, { key });
}
function fetchTopicError(key: string, err: Error): Action {
  return action(Actions.LOAD_TOPIC_ERROR, err, { key });
}
export function updateTopic(key: string, topicDTO: Object,
                            updateUrl: boolean): Action {
  return action(Actions.UPDATE_TOPIC, topicDTO, { key, updateUrl })
}
function updateTopicSuccess(key: string, payload: Object): Action {
  return action(Actions.UPDATE_TOPIC_SUCCESS, payload, { key })
}
function updateTopicError(key: string, err: Error): Action {
  return action(Actions.UPDATE_TOPIC_ERROR, err, { key })
}
export function search(query: string): Action {
  return action(Actions.SEARCH_TOPIC, { query });
}
function searchSuccess(payload: Object): Action {
  return action(Actions.SEARCH_TOPIC_SUCCESS, payload);
}
function searchError(err: Error): Action {
  return action(Actions.SEARCH_TOPIC_ERROR, err);
}
// UI actions
export function setTab(tabId: number): Action {
  return action(Actions.SET_TAB, tabId);
}

// Side effects
function* fetchRootAsync(action: Action): Generator<Function, void, void> {
  try {
    const payload = yield call(api.fetchRoot);
    if (!payload) {
      const err = new Error("Received no data");
      yield put(showErrorMessage(err));
      yield put(fetchRootError(err));
    } else {
      yield put(fetchRootSuccess(payload.data));
    }
  } catch (err) {
    yield put(showErrorMessage(err));
    yield put(fetchRootError(err));
  }
}

function* fetchTopicAsync(action: Action): Generator<Function, void, void> {
  const key = action.meta.key;
  try {
    const payload = yield call(api.fetchTopic, key);
    if (!payload) {
      const err = new Error("Topic not found");
      yield put(showMessage("Topic not found"));
      yield put(fetchTopicError(key, err));
    } else {
      yield put(fetchTopicSuccess(key, payload.data));
    }
  } catch (err) {
    yield put(showMessage("Topic not found"));
    yield put(fetchTopicError(key, err));
  }
}

function* updateTopicAsync(action: Action): Generator<Function, void, void> {
  const {key, updateUrl} = action.meta;
  try {
    const payload = yield call(api.updateTopic, action.payload);
    if (!payload) {
      const err = new Error("Received no data");
      yield put(updateTopicError(key, err));
      yield put(showErrorMessage(err))
    } else {
      yield put(updateTopicSuccess(key,payload.data));
      if (updateUrl) {
        yield put(push(payload.data.key));
      }
      yield put(showMessage("Updated the topic successfully."));
    }
  } catch (err) {
    yield put(updateTopicError(key, err));
    yield put(showErrorMessage(err))
  }
}

function* searchAsync(action: Action): Generator<Function, void, void> {
  try {
    const payload = yield call(api.search, action.payload.query);
    if (!payload) {
      const err = new Error("No topics found");
      yield put(showMessage("No topics found"));
      yield put(searchError(err));
    } else {
      yield put(searchSuccess(payload.data));
    }
  } catch (err) {
    yield put(showMessage("No topics found"));
    yield put(searchError(err));
  }
}

function* watchFetchRoot(): Generator<Function, void, void> {
  yield takeLatest(Actions.LOAD_ROOT, fetchRootAsync);
}

function* watchFetchTopic(): Generator<Function, void, void> {
  yield takeLatest(Actions.LOAD_TOPIC, fetchTopicAsync);
}

function* watchUpdateTopic(): Generator<Function, void, void> {
  yield takeLatest(Actions.UPDATE_TOPIC, updateTopicAsync);
}

function* watchSearch(): Generator<Function, void, void> {
  yield takeLatest(Actions.SEARCH_TOPIC, searchAsync);
}

export function* helpSagas(): Generator<Function, void, void> {
  yield all([fork(watchFetchRoot), fork(watchFetchTopic), fork(watchUpdateTopic), fork(watchSearch)]);
}
