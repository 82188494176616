// @flow
import {exportPC2, exportPrintData} from "./duck";

export const buildSiteMenu = (siteId: number) => {
  return [
    {
      name: "siteExport",
      label: "Export",
      children: [
        {
          name: "siteExport/printData",
          label: "Print data",
          reduxAction: exportPrintData(siteId)
        },
        { name: "siteExport/PC", label: "PC^2", reduxAction: exportPC2(siteId) }
      ]
    },
    {
      name: "manageSite",
      label: "Manage site",
      children: [
        {
          name: "manageSite/setupSite",
          label: "Setup site",
          to: `/private/site/${siteId}`
        }
      ]
    },
    {
      name: "siteSearches",
      label: "Searches",
      children: [
        {
          name: "siteSearches/institutions",
          label: "Institutions",
          to: `/private/site/${siteId}/search/institutions`
        },
        {
          name: "siteSearches/Teams",
          label: "Teams",
          to: `/private/site/${siteId}/search/teams`
        }
      ]
    },
    {
      name: "siteReports",
      label: "Reports",
      children: [
        {
          name: "siteReports/tshirts",
          label: "T-shirts in site",
          to: `/private/site/${siteId}/tshirt`
        }
      ]
    }
  ];
};
