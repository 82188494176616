/**
 * Pretty much a div that takes full screen
 *
 * @flow
 */
import React from "react";
import {withStyles} from "@material-ui/core/styles";

const styles = {
  root: { position: "fixed", width: "100%", height: "100%" }
};

type Props = {
  classes: Object,
  children: any
};

const Fullscreen = ({ classes, children }: Props) => {
  return <div className={classes.root}>{children}</div>;
};

export default withStyles(styles)(Fullscreen);
