/**
 * Validation utility
 * @flow
 */
import XRegExp from "xregexp";
import {Map} from "immutable";

export type Constraints = {
  minLength?: string,
  maxLength?: string,
  pattern?: string,
  patternM?: string,
  email?: string,
  url?: string,
  required?: string,
  min?: number,
  max?: number
};

/**
 * Returs error string if invalid, otherwise gives null/undefined
 *
 * @param {Map<Constraints>} constraints to check against
 * @param {string} field to validate
 */
export function validateField(
  constraints: Map<string, any> = Map({}),
  field: string = "",
  requireNeeded: boolean = false
): ?string {
  if (!constraints) {
    constraints = Map({});
  }
  if (field === undefined || field === null || field.length === 0) {
    if (
      constraints.get("required") ||
      (constraints.get("needed") && requireNeeded)
    ) {
      return "Required field.";
    } else return null;
  }
  if (constraints.get("email") || constraints.get("uniqueEmail")) {
    if (isEmail(field) === false) {
      return "Must be email.";
    }
  }
  if (constraints.get("minLength")) {
    const minLength: number = parseInt(constraints.get("minLength"), 10);
    if (field.length < minLength) {
      return `Minimum length: ${minLength}.`;
    }
  }
  if (constraints.get("maxLength")) {
    const maxLength: number = parseInt(constraints.get("maxLength"), 10);
    if (field.length > maxLength) {
      return `Maximum length: ${maxLength}.`;
    }
  }
  if (constraints.get("min")) {
    const min: number = parseInt(constraints.get("min"), 10);
    if (parseInt(field, 10) < min) {
      return `Minimum value: ${min}.`;
    }
  }
  if (constraints.get("max")) {
    const max: number = parseInt(constraints.get("max"), 10);
    if (parseInt(field, 10) > max) {
      return `Maximum value: ${max}.`;
    }
  }
  if (constraints.get("pattern")) {
    const regex = new XRegExp(regexTransform(constraints.get("pattern")));
    if (!regex.test(field)) {
      return (
        constraints.get("patternM") ||
        `Must match pattern: ${constraints.get("pattern") || ""}.`
      );
    }
  }
}

export function isEmail(email: string) {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

function regexTransform(regex: string): string {
  regex = regex.replace(/\{IsLatin\}/g, "{Latin}");
  regex = regex.replace(/\\p\{Alnum\}/g, "a-zA-z0-9");
  return regex;
}
