// @flow

/*
 * Security duck with action creators and reducers
 * see https://github.com/erikras/ducks-modular-redux for pattern guide
 *
 **/
import { fromJS, Map, Set } from "immutable";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";

import action from "common/utils/flux";
import * as api from "./api";
import { showErrorMessage, showMessage } from "containers/Snackbar/duck";
import type { Action } from "common/utils/flux";

// Action types
export const Actions = {
  GET: "icpc/eligibility/GET",
  GET_SUCCESS: "icpc/eligibility/GET_SUCCESS",
  GET_ERROR: "icpc/eligibility/GET_ERROR",
  POST: "icpc/eligibility/POST",
  POST_SUCCESS: "icpc/eligibility/POST_SUCCESS",
  POST_ERROR: "icpc/eligibility/POST_ERROR",
  REVALIDATE: "icpc/eligibility/REVALIDATE",
  REVALIDATE_SUCCESS: "icpc/eligibility/REVALIDATE_SUCCESS",
  REVALIDATE_ERROR: "icpc/eligibility/REVALIDATE_ERROR",
  EDIT: "icpc/eligibility/EDIT"
};

const defaultStore = Map({
  loading: Set(),
  changingStatus: Set()
});
// Reducers
export default function reducer(
  state: Map<string, any> = defaultStore,
  action: Action
) {
  const loading: Set<number> = state.get("loading");
  const changingStatus: Set<number> = state.get("changingStatus");
  const { payload } = action;
  switch (action.type) {
    case Actions.GET: {
      const { teamId } = payload;
      return state.set("loading", loading.add(teamId));
    }
    case Actions.GET_SUCCESS: {
      const { teamId } = payload;
      return state
        .set("loading", loading.delete(teamId))
        .set(teamId, fromJS(payload.eligibilityDto));
    }
    case Actions.GET_ERROR: {
      const { teamId } = payload;
      return state.set("loading", loading.delete(teamId));
    }
    case Actions.POST: {
      const { teamId } = payload;
      return state.set("changingStatus", changingStatus.add(teamId));
    }
    case Actions.POST_SUCCESS: {
      const { teamId, eligibilityDto } = payload;
      return state
        .set("changingStatus", changingStatus.delete(teamId))
        .set(teamId, fromJS(eligibilityDto));
    }
    case Actions.POST_ERROR: {
      const { teamId } = payload;
      return state.set("changingStatus", changingStatus.delete(teamId));
    }
    case Actions.REVALIDATE: {
      const { teamId } = payload;
      return state.set("changingStatus", changingStatus.add(teamId));
    }
    case Actions.REVALIDATE_SUCCESS: {
      const { teamId, eligibilityDto } = payload;
      return state
        .set("changingStatus", changingStatus.delete(teamId))
        .set(teamId, fromJS(eligibilityDto));
    }
    case Actions.REVALIDATE_ERROR: {
      const { teamId } = payload;
      return state.set("changingStatus", changingStatus.delete(teamId));
    }
    case Actions.EDIT: {
      const { teamId, val } = payload;
      const dto = state.get(teamId);
      if (!dto) {
        return state;
      }

      return state.update(teamId, () => dto.merge(val));
    }
    default:
      return state;
  }
}

// Action creators
export function getEligibility(teamId: number) {
  return action(Actions.GET, { teamId });
}
function getEligibilitySuccess(teamId: number, eligibilityDto: Object) {
  return action(Actions.GET_SUCCESS, { teamId, eligibilityDto });
}
function getEligibilityError(teamId: number) {
  return action(Actions.GET_ERROR, { teamId });
}
export function postEligibility(teamId: number, eligibility: Map<string, any>) {
  return action(Actions.POST, { teamId, eligibility });
}
function postEligibilitySuccess(teamId: number, eligibilityDto: Object) {
  return action(Actions.POST_SUCCESS, { teamId, eligibilityDto });
}
function postEligibilityError(teamId: number) {
  return action(Actions.POST_ERROR, { teamId });
}
export function revalidateEligibility(teamId: number) {
  return action(Actions.REVALIDATE, { teamId });
}
function revalidateEligibilitySuccess(teamId: number, eligibilityDto: Object) {
  return action(Actions.REVALIDATE_SUCCESS, { teamId, eligibilityDto });
}
function revalidateEligibilityError(teamId: number) {
  return action(Actions.REVALIDATE_ERROR, { teamId });
}
export function editEligibility(teamId: number, val: Object) {
  return action(Actions.EDIT, { teamId, val });
}

// Sagas
function* getSaga(action: Action): Generator<Function, void, void> {
  const { teamId } = action.payload;
  try {
    const payload = yield call(api.fetchEligibility, teamId);
    yield put(getEligibilitySuccess(teamId, payload.data));
  } catch (err) {
    yield put(showErrorMessage(err));
    yield put(getEligibilityError(teamId));
  }
}

function* postSaga(action: Action): Generator<Function, void, void> {
  const { teamId, eligibility } = action.payload;
  try {
    const payload = yield call(
      api.updateEligibility,
      teamId,
      eligibility.toJS()
    );
    yield put(postEligibilitySuccess(teamId, payload.data));
  } catch (err) {
    yield put(showErrorMessage(err));
    yield put(postEligibilityError(teamId));
  }
}

function* revalidateSaga(action: Action): Generator<Function, void, void> {
  const { teamId } = action.payload;
  try {
    const payload = yield call(api.revalidate, teamId);
    yield put(revalidateEligibilitySuccess(teamId, payload.data));
    yield put(showMessage("Eligibility revalidated."));
  } catch (err) {
    yield put(showErrorMessage(err));
    yield put(revalidateEligibilityError(teamId));
  }
}

function* watchGet(): Generator<Function, void, void> {
  yield takeLatest(Actions.GET, getSaga);
}

function* watchPost(): Generator<Function, void, void> {
  yield takeLatest(Actions.POST, postSaga);
}

function* watchRevalidate(): Generator<Function, void, void> {
  yield takeLatest(Actions.REVALIDATE, revalidateSaga);
}

export function* eligibilityModifierSagas(): Generator<Function, void, void> {
  yield all([fork(watchGet), fork(watchPost), fork(watchRevalidate)]);
}
