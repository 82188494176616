// @flow
/**
 * This file configures axios instance.
 * Import this instance every time you want to do a server call.
 * Usage:
 *    import api from "api";
 *    api.get("/users", ...) ...will produce full URL for a request
 */

import axios from "axios";

const api = axios.create();

export const CLIENT = process.env.REACT_APP_CLIENT;
export const SERVER = process.env.REACT_APP_SERVER;
export const HOTEL_SERVER = process.env.REACT_APP_HOTEL;
api.defaults.baseURL = SERVER;

export default api;
