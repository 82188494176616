// @flow
/**
 * Snackbar selectors
 **/
import {createSelector} from "reselect";
import {isImmutable,Map} from "immutable";

const selectSnackbar = (state: Map<string, any>) => {
  return state.get("snackbar");
};

const selectOpen = createSelector(
  selectSnackbar,
  (snackbar: Map<string, any>) => {
    return snackbar.get("open");
  }
);

const selectMessage = createSelector(
  selectSnackbar,
  (snackbar: Map<string, any>) => {
    return snackbar.get("message");
  }
);

const selectVertical = createSelector(
  selectSnackbar,
  (snackbar: Map<string, any>) => {
    return snackbar.get("vertical");
  }
);

const selectHorizontal = createSelector(
  selectSnackbar,
  (snackbar: Map<string, any>) => {
    return snackbar.get("horizontal");
  }
);

const selectAutoHideDuration = createSelector(
  selectSnackbar,
  (snackbar: Map<string, any>) => {
    return snackbar.get("autoHideDuration");
  }
);

const selectAction = createSelector(
  selectSnackbar,
  (snackbar: Map<string, any>) => {
    const action = snackbar.get("action");
    return action ? (isImmutable(action)? action.toJS(): action) : null;
  }
);

const selectActionText = createSelector(
  selectSnackbar,
  (snackbar: Map<string, any>) => {
    return snackbar.get("actionText");
  }
);

const selectVariant = createSelector(
  selectSnackbar,
  (snackbar: Map<string, any>) => {
    return snackbar.get("variant");
  }
);

export {
  selectOpen,
  selectMessage,
  selectHorizontal,
  selectVertical,
  selectAutoHideDuration,
  selectAction,
  selectActionText,
  selectVariant
};
