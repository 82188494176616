// @flow
import api from "api";
import * as resources from "resources";

export const fetchRestrictions = (teamId: number) => {
  return api.get(resources.DataGET.TEAM_RESTRICTIONS(teamId));
};

export const fetchTeamDto = (teamId: number) => {
  return api.get(resources.DataGET.TEAM_DTO(teamId));
};

export const fetchTeamMembers = (teamId: number) => {
  return api.get(resources.DataGET.TEAM_MEMBERS(teamId));
};

export const fetchSiteDetailsDto = (siteId: number) => {
  return api.get(resources.DataGET.SITE_DETAILS_DTO(siteId));
};

export const fetchSites = (teamId: number) => {
  return api.get(resources.DataGET.TEAM_OTHER_SITES(teamId));
};

export const fetchGenericFields = (teamId: number) => {
  return api.get(resources.DataGET.TEAM_GENERIC_FIELDS(teamId));
};

export const postGenericFields = (teamId: number, fields: Object) => {
  return api.post(resources.DataPOST.TEAM_GENERIC_FIELDS(teamId), fields);
};

export const moveTeam = (teamId: number, siteId: number) => {
  return api.put(resources.DataPUT.TEAM_MOVE(teamId), siteId);
};

export const updateTeam = (teamId: number, teamDto: Object) => {
  return api.post(resources.DataPOST.TEAM_UPDATE(teamId), teamDto);
};

export const updateTeamMember = (teamMemberId: number, teamMemberDto: Object) => {
  return api.post(resources.DataPOST.TEAMMEMBER_UPDATE(teamMemberId), teamMemberDto);
};

export const createTeamMember = (teamId: number, teamMemberDto: Object) => {
  return api.post(resources.DataPOST.TEAM_MEMBERS_ADD(teamId), teamMemberDto);
};

export const deleteTeamMember = (memberId: number) => {
  return api.delete(resources.DataDELETE.TEAM_MEMBER(memberId));
};

export const deleteTeam = (teamId: number) => {
  return api.delete(resources.DataDELETE.TEAM(teamId));
};

export const cancelTeam = (teamId: number) => {
  return api.post(resources.DataPOST.TEAM_CANCEL(teamId));
};

export const findSuggestPerson = (personId: number) => {
  return api.get(resources.DataGET.PERSON_NAME_BY_ID(personId));
};

export const changeCoach = (teamId: number, coach: Object) => {
  return api.post(resources.DataPUT.TEAM_UPDATE_COACH(teamId), coach);
};

export const findOffensiveWordsInTeamName = (teamId: number) => {
  return api.get(resources.DataGET.TEAM_NAME_OFFENSIVE(teamId));
};

export const setTeamNameInoffensive = (teamId: number) => {
  return api.post(resources.DataPOST.TEAM_NAME_INOFFENSIVE(teamId));
};
