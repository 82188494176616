// @flow

/**
 * This function check if there is a Message.
 * We use package edu.baylor.icpc.rest.mapping.exceptions.message.Message, but not all Exceptions are mapped to this DTO,
 * thus it is necessary to check whether an error object follows that structure.
 * To avoid code repetition, use this function, since you need to wrap in try/catch all the time
 * @param {Object} error object
 */
export function errorMessage(error: Object): string {
  let detail: ?string = null;
  try {
    if (typeof error.response.data === 'object' && error.response.data !== null) {
      detail = error.response.data.detail;
    } else {
      detail = error. response.data;
    }
  } catch (e) {
    return error.message;
  }
  return detail || error.message;
}
