/**
 * @flow
 * This file exports
 */
import {defaultMemoize} from "reselect";

const LOCAL_STORAGE_KEY_PREFIX = "@icpc";

const loadState = (defaultState: ?any = undefined) => {
  try {
    const serializedState = localStorage.getItem(LOCAL_STORAGE_KEY_PREFIX);
    if (serializedState === null || serializedState === undefined) {
      return defaultState;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return defaultState;
  }
};

export const saveState = (state: any) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(LOCAL_STORAGE_KEY_PREFIX, serializedState);
  } catch (err) {
    // Ignore write errors
  }
};

/**
 * Memoize the loadState function so the expensive JSON.parse is called just once
 * You want to use this function instead of loadState because we call it in multiple reducers
 * on bootstrap when initiating default state
 */
export const getPersistedState = defaultMemoize(loadState);

export const isDrawerOpen = (defaultState: boolean) => {
  const state = getPersistedState();
  if (state === undefined || state === null) {
    return defaultState;
  } else {
    return state.isDrawerOpen;
  }
};
