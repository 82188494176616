// @flow
import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import {withStyles} from "@material-ui/core/styles";

const styles = {
  actionButton: {
    color: "inherit"
  },
  icon: {
    fontSize: 20
  }
};

type Props = {
  onClose: Function,
  onAction?: Function
};

function Action(props: Props) {
  const { onClose, onAction } = props;
  if (!onAction)
    return (
      <IconButton
        key="close"
        aria-label="Close"
        color="inherit"
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
    );
  return (
    <Button
      color="secondary"
      size="small"
      className={props.classes.actionButton}
      onClick={onAction}
    >
      {props.actionText || "redo"}
    </Button>
  );
}

export default withStyles(styles)(Action);
