// @flow
/**
 * Site selectors
 **/
import {createSelector} from "reselect";
import {Map} from "immutable";

const getSite = (state: Map<string, any>) => {
  return state.get("site");
};

const getBreadcrumbs = createSelector(getSite, (site: Map<string, any>) => {
  const breadcrumbs: Map<string, any> = site.get("breadcrumbs");
  if (!breadcrumbs) return [];
  return breadcrumbs.toJS();
});

const getCurrentSite = createSelector(getSite, (site: Map<string, any>) => {
  const currentSite: Map<string, any> = site.get("currentSite");
  if (!currentSite) return {};
  // we return JS object here for convenient use
  // you should not do it outside selectors to prevent unnecessary updates
  return currentSite.toJS();
});

const isLoading = createSelector(getSite, (site: Map<string, any>) => {
  return site.get("loading");
});

const getOtherSitesMenu = createSelector(getSite, (site: Map<string, any>) => {
  const otherSites = site.get("otherSites");
  const otherSitesMenu = {
    name: "otherSites",
    label: "Other sites",
    children: []
  };
  for (let otherSite of otherSites) {
    otherSitesMenu.children.push({
      name: `otherSites/${otherSite.get("id")}`,
      label: otherSite.get("name"),
      to: `/private/site/${otherSite.get("id")}`
    });
  }
  return otherSitesMenu;
});

export { getCurrentSite, getBreadcrumbs, isLoading, getOtherSitesMenu };
