// @flow
import FileSaver from "file-saver";

type DownloadableFile = {
  data: string,
  fileName: string,
};

export const FileType = {
  CSV: "csv",
  TSV: "tsv",
  TAB: "tab",
  TXT: "txt",
  // EXCEL: "xlsx",
  // JSON: "json"
};

const download = (
  file: DownloadableFile,
  fileName: ?string,
  fileType?: ?string
) => {
  let blob = new Blob([base64ToArrayBuffer(file.data)]);
  let name = fileName ? fileName : file.fileName;
  if (fileType) name += fileType;
  FileSaver.saveAs(blob, name);
};

export const base64ToArrayBuffer = (base64: string) => {
  const binary_string = window.atob(base64);
  const len = binary_string.length;
  let bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes.buffer;
};

const ICPCMime = {
  BINARY: "bin",
  BMP: "bmp",
  BZIP: "bz2",
  CSV: "csv",
  DVI: "dvi",
  GIF: "gif",
  GZIP: "gz",
  JAR: "jar",
  JPEG: "jpg",
  MS_EXCEL: "xls",
  MS_POWERPOINT: "ppt",
  MS_WORD: "doc",
  PDF: "pdf",
  PNG: "png",
  POSTSCRIPT: "ps",
  SVG: "svg",
  TAR: "tar",
  TIFF: "tiff",
  TSV: "tsv",
  TXT: "txt",
  XML: "xml",
  ZIP: "zip",
};

export const getICPCMimeExtension = (mime: string) => {
  return ICPCMime[mime];
};

export default download;
