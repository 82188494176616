// @flow

/*
 * Person registration duck with action creators and reducers
 * see https://github.com/erikras/ducks-modular-redux for pattern guide
 *
 **/
import {fromJS, List, Map} from "immutable";
import {all, call, fork, put, select, takeLatest} from "redux-saga/effects";

import action from "common/utils/flux";
import * as snackbar from "containers/Snackbar/duck";
import * as api from "./api";
import * as s from "./selectors";
import {errorMessage} from "common/utils/error";
import type { Action } from "common/utils/flux";

// Action types
export const Actions = {
  PERSON_DTO_GET: "icpc/personRegistration/GET_PERSON_DTO",
  PERSON_DTO_GET_SUCCESS: "icpc/personRegistration/GET_PERSON_DTO_SUCCESS",
  PERSON_DTO_GET_ERROR: "icpc/personRegistration/GET_PERSON_DTO_ERROR",
  UPDATE_GENERIC_FIELD: "icpc/personRegistration/UPDATE_GENERIC_FIELD",
  GET_GENERIC_FIELDS: "icpc/personRegistration/GET_GENERIC_FIELDS",
  GET_GENERIC_FIELDS_SUCCESS:
    "icpc/personRegistration/GET_GENERIC_FIELDS_SUCCESS",
  GET_GENERIC_FIELDS_ERROR: "icpc/personRegistration/GET_GENERIC_FIELDS_ERROR",
  POST_GENERIC_FIELDS: "icpc/personRegistration/POST_GENERIC_FIELDS",
  POST_GENERIC_FIELDS_SUCCESS:
    "icpc/personRegistration/POST_GENERIC_FIELDS_SUCCESS",
  POST_GENERIC_FIELDS_ERROR:
    "icpc/personRegistration/POST_GENERIC_FIELDS_ERROR",
  PERSON_REGISTRATION_STATUS_GET:
    "icpc/personRegistration/GET_PERSON_REGISTRATION_STATUS",
  PERSON_REGISTRATION_STATUS_GET_SUCCESS:
    "icpc/personRegistration/GET_PERSON_REGISTRATION_STATUS_SUCCESS",
  PERSON_REGISTRATION_STATUS_GET_ERROR:
    "icpc/personRegistration/GET_PERSON_REGISTRATION_STATUS_ERROR",
  PERSON_REGISTRATION_GET_REFERENCES: "icpc/personRegistration/GET_REFERENCES",
  PERSON_REGISTRATION_GET_REFERENCES_SUCCESS:
    "icpc/personRegistration/GET_REFERENCES_SUCCESS",
  PERSON_REGISTRATION_GET_REFERENCES_ERROR:
    "icpc/personRegistration/GET_REFERENCES_ERROR",
  PERSON_REGCOMPLETE_STATUS: "icpc/personRegistration/GET_REGCOMPLETE_STATUS",
  PERSON_REGCOMPLETE_STATUS_SUCCESS:
    "icpc/personRegistration/GET_REGCOMPLETE_STATUS_SUCCESS",
  PERSON_REGCOMPLETE_STATUS_ERROR:
    "icpc/personRegistration/GET_REGCOMPLETE_STATUS_ERROR",
  PERSON_SWITCH_ENABLED: "icpc/personRegistration/SWITCH_ENABLED",
  PERSON_SWITCH_ENABLED_SUCCESS:
    "icpc/personRegistration/SWITCH_ENABLED_SUCCESS",
  PERSON_SWITCH_ENABLED_ERROR: "icpc/personRegistration/SWITCH_ENABLED_ERROR",
  PERSON_SWITCH_REGCOMPLETE: "icpc/personRegistration/SWITCH_REGCOMPLETE",
  PERSON_SWITCH_REGCOMPLETE_SUCCESS:
    "icpc/personRegistration/SWITCH_REGCOMPLETE_SUCCESS",
  PERSON_SWITCH_REGCOMPLETE_ERROR:
    "icpc/personRegistration/SWITCH_REGCOMPLETE_ERROR",
  PERSON_UPDATE_STORE: "icpc/personRegistration/UPDATE_STORE",
  PERSON_ICPCID_KEY_GET: "icpc/personRegistration/GET_ICPCID_KEY",
  PERSON_ICPCID_KEY_GET_SUCCESS:
    "icpc/personRegistration/GET_ICPCID_KEY_SUCCESS",
  PERSON_ICPCID_KEY_GET_ERROR: "icpc/personRegistration/GET_ICPCID_KEY_ERROR"
};

const defaultStore = Map({
  loading: false,
  error: false,
  referencesLoading: false,
  referencesError: false,
  personDto: Map(),
  registrationStatusDto: Map(),
  switchingEnabled: false,
  switchingRegcomplete: false,
  registrationComplete: true,
  registrationIssue: "",
  genericFields: List(),
  genericFieldsPosting: false
});

// Reducers
export default function reducer(
  state: Map<string, any> = defaultStore,
  action: Action
) {
  switch (action.type) {
    case Actions.PERSON_DTO_GET:
      return state.set("loading", true);
    case Actions.PERSON_DTO_GET_SUCCESS:
      return state.merge({ loading: false, error: false, ...action.payload });
    case Actions.PERSON_DTO_GET_ERROR:
      return state.merge({ loading: false, error: true });
    case Actions.PERSON_REGISTRATION_GET_REFERENCES:
      return state.set("referencesLoading", true);
    case Actions.PERSON_REGISTRATION_GET_REFERENCES_SUCCESS:
      return state.merge({
        referencesLoading: false,
        referencesError: false,
        ...action.payload
      });
    case Actions.PERSON_REGISTRATION_GET_REFERENCES_ERROR:
      return state.merge({ referencesLoading: false, referencesError: true });
    case Actions.PERSON_REGISTRATION_STATUS_GET_SUCCESS:
      return state.merge({ ...action.payload });
    case Actions.PERSON_UPDATE_STORE:
      return state.merge({ ...action.payload });
    case Actions.PERSON_SWITCH_ENABLED:
      return state.set("switchingEnabled", true);
    case Actions.PERSON_SWITCH_ENABLED_SUCCESS:
      return state.merge({
        switchingEnabled: false,
        enabled: action.payload
      });
    case Actions.PERSON_SWITCH_ENABLED_ERROR:
      return state.set("switchingEnabled", false);
    case Actions.PERSON_SWITCH_REGCOMPLETE:
      return state.set("switchingRegcomplete", true);
    case Actions.PERSON_SWITCH_REGCOMPLETE_SUCCESS:
      return state.merge({
        switchingRegcomplete: false,
        registrationComplete: action.payload
      });
    case Actions.PERSON_REGCOMPLETE_STATUS_SUCCESS:
      return state.merge({
        registrationIssue: action.payload.registrationIssue,
        registrationComplete: action.payload.registrationComplete
      });
    case Actions.PERSON_SWITCH_REGCOMPLETE_ERROR:
      return state.set("switchingRegcomplete", false);
    case Actions.PERSON_ICPCID_KEY_GET:
      return state.set("icpcIdKeyLoading", true);
    case Actions.PERSON_ICPCID_KEY_GET_SUCCESS:
      return state.merge({
        icpcIdKeyLoading: false,
        icpcIdKey: action.payload
      });
    case Actions.PERSON_ICPCID_KEY_GET_ERROR:
      return state.merge({ icpcIdKeyLoading: false, icpcIdKeyError: true });
    case Actions.UPDATE_GENERIC_FIELD: {
      const index = action.payload.index;
      const genericField = state.getIn(["genericFields", index]);
      if (genericField) {
        return state.setIn(
          ["genericFields", index],
          genericField.set("response", action.payload.response)
        );
      } else {
        return state;
      }
    }
    case Actions.GET_GENERIC_FIELDS: {
      // not interested in loaders
      return state;
    }
    case Actions.GET_GENERIC_FIELDS_SUCCESS:
      return state.set("genericFields", fromJS(action.payload));
    case Actions.GET_GENERIC_FIELDS_ERROR: {
      // not interested in errors
      return state;
    }
    case Actions.POST_GENERIC_FIELDS: {
      return state.set("genericFieldsPosting", true);
    }
    case Actions.POST_GENERIC_FIELDS_SUCCESS:
      return state
        .set("genericFields", fromJS(action.payload))
        .set("genericFieldsPosting", false);
    case Actions.POST_GENERIC_FIELDS_ERROR: {
      return state.set("genericFieldsPosting", false);
    }
    default:
      return state;
  }
}

// Action creators
export function fetchPersonDto(personId: number): Action {
  return action(Actions.PERSON_DTO_GET, personId);
}
function fetchPersonDtoSuccess(payload: Object): Action {
  return action(Actions.PERSON_DTO_GET_SUCCESS, payload);
}
function fetchPersonDtoError(err: Error): Action {
  return action(Actions.PERSON_DTO_GET_ERROR, err);
}
export function fetchPersonRegistrationStatus(personId: number): Action {
  return action(Actions.PERSON_REGISTRATION_STATUS_GET, personId);
}
function fetchPersonRegistrationStatusSuccess(payload: Object): Action {
  return action(Actions.PERSON_REGISTRATION_STATUS_GET_SUCCESS, payload);
}
function fetchPersonRegistrationStatusError(err: Error): Action {
  return action(Actions.PERSON_REGISTRATION_STATUS_GET_ERROR, err);
}
export function fetchPersonRegCompleteStatus(personId: number): Action {
  return action(Actions.PERSON_REGCOMPLETE_STATUS, personId);
}
function fetchPersonRegCompleteStatusSuccess(payload: Object): Action {
  return action(Actions.PERSON_REGCOMPLETE_STATUS_SUCCESS, payload);
}
function fetchPersonRegCompleteStatusError(err: Error): Action {
  return action(Actions.PERSON_REGCOMPLETE_STATUS_ERROR, err);
}
export function fetchReferences(personId: number, year: number): Action {
  return action(Actions.PERSON_REGISTRATION_GET_REFERENCES, { personId, year });
}
function fetchReferencesSuccess(payload: Object): Action {
  return action(Actions.PERSON_REGISTRATION_GET_REFERENCES_SUCCESS, payload);
}
function fetchReferencesError(err: Error): Action {
  return action(Actions.PERSON_REGISTRATION_GET_REFERENCES_ERROR, err);
}
export function switchEnabled(personId: number): Action {
  return action(Actions.PERSON_SWITCH_ENABLED, personId);
}
function switchEnabledSuccess(payload: Object): Action {
  return action(Actions.PERSON_SWITCH_ENABLED_SUCCESS, payload);
}
function switchEnabledError(err: Error): Action {
  return action(Actions.PERSON_SWITCH_ENABLED_ERROR, err);
}
export function switchRegcomplete(personId: number): Action {
  return action(Actions.PERSON_SWITCH_REGCOMPLETE, personId);
}
function switchRegcompleteSuccess(payload: Object): Action {
  return action(Actions.PERSON_SWITCH_REGCOMPLETE_SUCCESS, payload);
}
function switchRegcompleteError(err: Error): Action {
  return action(Actions.PERSON_SWITCH_REGCOMPLETE_ERROR, err);
}
export function updateStore(data: Object): Action {
  return action(Actions.PERSON_UPDATE_STORE, data);
}
export function fetchICPCIDKey(personId: number): Action {
  return action(Actions.PERSON_ICPCID_KEY_GET, personId);
}
function fetchICPCIDKeySuccess(payload: Object): Action {
  return action(Actions.PERSON_ICPCID_KEY_GET_SUCCESS, payload);
}
function fetchICPCIDKeyError(err: Error): Action {
  return action(Actions.PERSON_ICPCID_KEY_GET_ERROR, err);
}
export function getGenericFields(personId: number): Action {
  return action(Actions.GET_GENERIC_FIELDS, { personId });
}
function getGenericFieldsSuccess(payload: Object): Action {
  return action(Actions.GET_GENERIC_FIELDS_SUCCESS, payload);
}
function getGenericFieldsError(err: Error): Action {
  return action(Actions.GET_GENERIC_FIELDS_ERROR, err);
}
export function postGenericFields(personId: number): Action {
  return action(Actions.POST_GENERIC_FIELDS, { personId });
}
function postGenericFieldsSuccess(payload: Object): Action {
  return action(Actions.POST_GENERIC_FIELDS_SUCCESS, payload);
}
function postGenericFieldsError(err: Error): Action {
  return action(Actions.POST_GENERIC_FIELDS_ERROR, err);
}
export function updateGenericField(index: number, response: string) {
  return action(Actions.UPDATE_GENERIC_FIELD, { index, response });
}
// Side effects
function* fetchPersonDtoSaga(action: Action) {
  try {
    const personDtoPayload = yield call(api.fetchPersonDto, action.payload);
    if (!personDtoPayload) {
      const err = new Error("Received no person data");
      yield put(snackbar.showMessage("Received no person data"));
      yield put(fetchPersonDtoError(err));
    } else {
      yield put(fetchPersonDtoSuccess(personDtoPayload.data));
    }
  } catch (err) {
    yield put(snackbar.showMessage(errorMessage(err)));
    yield put(fetchPersonDtoError(new Error("Received no data")));
  }
}

function* fetchICPCIDKeySaga(action: Action) {
  try {
    const icpcIdPayload = yield call(api.fetchIcpcIdKey, action.payload);
    yield put(fetchICPCIDKeySuccess(icpcIdPayload.data));
  } catch (err) {
    yield put(snackbar.showMessage(errorMessage(err)));
    yield put(fetchICPCIDKeyError(new Error("Received no data")));
  }
}

function* fetchRegistrationStatusDtoSaga(action: Action) {
  try {
    const registrationStatusDtoPayload = yield call(
      api.fetchRegistrationStatusDto,
      action.payload
    );
    if (!registrationStatusDtoPayload) {
      const err = new Error("Received no person registration data");
      yield put(snackbar.showMessage("Received no person registration data"));
      yield put(fetchPersonRegistrationStatusError(err));
    } else {
      yield put(
        fetchPersonRegistrationStatusSuccess(registrationStatusDtoPayload.data)
      );
    }
  } catch (err) {
    yield put(snackbar.showMessage(errorMessage(err)));
    yield put(
      fetchPersonRegistrationStatusError(new Error("Received no data"))
    );
  }
}

function* fetchReferencesData(action: Action) {
  try {
    const referencesPayload = yield call(
      api.fetchReferencesDto,
      action.payload.personId,
      action.payload.year
    );
    if (!referencesPayload) {
      const err = new Error("Received no references data");
      yield put(snackbar.showMessage(errorMessage(err)));
      yield put(fetchReferencesError(err));
    } else {
      yield put(fetchReferencesSuccess(referencesPayload.data));
    }
  } catch (err) {
    yield put(snackbar.showMessage(errorMessage(err)));
    yield put(fetchReferencesError(new Error("Received no data")));
  }
}

function* switchEnabledSaga(action: Action) {
  try {
    const enabledPayload = yield call(api.switchEnabled, action.payload);
    yield put(switchEnabledSuccess(enabledPayload.data));
  } catch (err) {
    yield put(snackbar.showMessage(errorMessage(err)));
    yield put(switchEnabledError(err));
  }
}

function* switchRegCompleteSaga(action: Action) {
  try {
    const enabledPayload = yield call(api.switchRegcomplete, action.payload);
    yield put(switchRegcompleteSuccess(enabledPayload.data));
  } catch (err) {
    yield put(snackbar.showMessage(errorMessage(err)));
    yield put(switchRegcompleteError(err));
  }
}

function* fetchRegcomplete(action: Action) {
  try {
    const payload = yield call(api.fetchRegCompleteStatus, action.payload);
    yield put(fetchPersonRegCompleteStatusSuccess(payload.data));
  } catch (err) {
    yield put(snackbar.showMessage(errorMessage(err)));
    yield put(fetchPersonRegCompleteStatusError(err));
  }
}

function* fetchGenericFieldsSaga(action: Action) {
  try {
    const payload = yield call(api.fetchGenericFields, action.payload.personId);
    yield put(getGenericFieldsSuccess(payload.data));
  } catch (err) {
    yield put(snackbar.showMessage(errorMessage(err)));
    yield put(getGenericFieldsError(err));
  }
}

function* postGenericFieldsSaga(action: Action) {
  try {
    const genericFields = yield select(s.getGenericFields);
    const payload = yield call(
      api.postGenericFields,
      action.payload.personId,
      genericFields.toJS()
    );
    yield put(postGenericFieldsSuccess(payload.data));
    yield put(fetchPersonRegistrationStatus(action.payload.personId));
    yield put(snackbar.showMessage("Extra fields updated."));
  } catch (err) {
    yield put(snackbar.showMessage(errorMessage(err)));
    yield put(postGenericFieldsError(err));
  }
}

function* watchFetchPersonDto(): Generator<Function, void, void> {
  yield takeLatest(Actions.PERSON_DTO_GET, fetchPersonDtoSaga);
}

function* watchFetchReferences(): Generator<Function, void, void> {
  yield takeLatest(
    Actions.PERSON_REGISTRATION_GET_REFERENCES,
    fetchReferencesData
  );
}

function* watchSwitchEnabled(): Generator<Function, void, void> {
  yield takeLatest(Actions.PERSON_SWITCH_ENABLED, switchEnabledSaga);
}

function* watchSwitRegcomplete(): Generator<Function, void, void> {
  yield takeLatest(Actions.PERSON_SWITCH_REGCOMPLETE, switchRegCompleteSaga);
}

function* watchFetchRegcompleteStatus(): Generator<Function, void, void> {
  yield takeLatest(Actions.PERSON_REGCOMPLETE_STATUS, fetchRegcomplete);
}

function* watchFetchICPCIDKey(): Generator<Function, void, void> {
  yield takeLatest(Actions.PERSON_ICPCID_KEY_GET, fetchICPCIDKeySaga);
}

function* watchFetchRegistrationStatus(): Generator<Function, void, void> {
  yield takeLatest(
    Actions.PERSON_REGISTRATION_STATUS_GET,
    fetchRegistrationStatusDtoSaga
  );
}

function* watchFetchGenericFields(): Generator<Function, void, void> {
  yield takeLatest(Actions.GET_GENERIC_FIELDS, fetchGenericFieldsSaga);
}

function* watchPostGenericFields(): Generator<Function, void, void> {
  yield takeLatest(Actions.POST_GENERIC_FIELDS, postGenericFieldsSaga);
}

export function* personRegistrationSagas(): Generator<Function, void, void> {
  yield all([
    fork(watchFetchPersonDto),
    fork(watchFetchRegistrationStatus),
    fork(watchFetchReferences),
    fork(watchSwitchEnabled),
    fork(watchSwitRegcomplete),
    fork(watchFetchRegcompleteStatus),
    fork(watchFetchICPCIDKey),
    fork(watchFetchGenericFields),
    fork(watchPostGenericFields)
  ]);
}
