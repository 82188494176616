/*
 * Team registration duck with action creators and reducers
 * see https://github.com/erikras/ducks-modular-redux for pattern guide
 *
 * @flow
 **/
import { List, Map } from "immutable";
import { all, call, fork, put, select, takeLatest } from "redux-saga/effects";
import { push } from "connected-react-router/immutable";
import { validateField } from "common/utils/validation";
import action from "common/utils/flux";
import * as snackbar from "containers/Snackbar/duck";
import * as api from "./api";
import { getActiveStep, getTeamRegistrationDtos } from "./selectors";
import { errorMessage } from "common/utils/error";
import type { Action } from "common/utils/flux";
import type { TeamRegistrationDto } from "./types";

// Action types
export const Actions = {
  STEP_NEXT: "icpc/teamRegistration/STEP_NEXT",
  STEP_BACK: "icpc/teamRegistration/STEP_BACK",
  SET_CONTEST: "icpc/teamRegistration/SET_CONTEST",
  SET_SITE: "icpc/teamRegistration/SET_SITE",
  SET_INSTITUTION: "icpc/teamRegistration/SET_INSTITUTION",
  SET_NUMBER_OF_TEAMS: "icpc/teamRegistration/SET_NUMBER_OF_TEAMS",
  REMOVE_LAST_TEAM: "icpc/teamRegistration/REMOVE_LAST_TEAM",
  SET_TEAM_NAME: "icpc/teamRegistration/SET_TEAM_NAME",
  SET_STUDENT_COACH: "icpc/teamRegistration/SET_STUDENT_COACH",
  TEAM_MEMBER_ADD: "icpc/teamRegistration/TEAM_MEMBER_ADD",
  TEAM_MEMBER_EDIT: "icpc/teamRegistration/TEAM_MEMBER_EDIT",
  TEAM_MEMBER_DELETE: "icpc/teamRegistration/TEAM_MEMBER_DELETE",
  TEAM_MEMBERS_CLEAR_ALL: "icpc/teamRegistration/TEAM_MEMBERS_CLEAR_ALL",
  CONFIRM: "icpc/teamRegistration/CONFIRM",
  CONFIRM_SUCCESS: "icpc/teamRegistration/CONFIRM_SUCCESS",
  CONFIRM_ERROR: "icpc/teamRegistration/CONFIRM_ERROR",
};

// constraints for team name
const constraints = Map({
  minLength: "2",
  pattern: "^([\\p{IsLatin}\\d\\s\\pS\\pP]+)$",
  patternM:
    "Team name doesn't follow the pattern. Only latin letters and symbols are allowed.",
  required: "true",
  maxLength: "2147483647",
});

// validates team name based on constraints above
const validateTeam = (name: string) => validateField(constraints, name);

// creates TeamRegistrationDto with team name
const teamFactory = (name: string = "") =>
  Map({
    name,
    errorText: validateTeam(name),
    // members: List([teamMemberFactory(),teamMemberFactory(),teamMemberFactory()])
    members: List(),
  });

// creates empty TeamMemberRegistrationDto
const teamMemberFactory = () =>
  Map({
    role: null,
    person: Map({
      id: null,
    }),
    certificateRole: null,
    badgeRole: null,
  });

// default state for team registration
const defaultStore = Map({
  contestAbbr: null,
  siteId: null,
  activeStep: 0,
  iuaId: null,
  numberOfTeams: 1,
  teams: List([teamFactory()]),
  isCreating: false,
  isStudentCoach: false,
  // server generated message
  errorMessage: "",
});

const STEPS_MAX_INDEX = 3;

// Reducers
export default function reducer(
  state: Map<string, any> = defaultStore,
  action: Action
) {
  switch (action.type) {
    case Actions.STEP_NEXT: {
      const activeStep = state.get("activeStep");
      if (activeStep < STEPS_MAX_INDEX) {
        return state.set("activeStep", activeStep + 1);
      } else {
        return state;
      }
    }
    case Actions.STEP_BACK: {
      const activeStep = state.get("activeStep");
      if (activeStep > 0) {
        return state.set("activeStep", activeStep - 1);
      } else {
        return state;
      }
    }
    case Actions.SET_CONTEST:
      return state.set("contestAbbr", action.payload);
    case Actions.SET_SITE:
      return state.set("siteId", action.payload);
    case Actions.SET_INSTITUTION:
      return state.set("iuaId", action.payload);
    case Actions.SET_NUMBER_OF_TEAMS: {
      let num = action.payload;
      if (num < 1) num = 1;
      if (num > 10) num = 10;
      let teams = List();
      if (num) {
        for (let i = 0; i < num; i++) {
          teams = teams.push(teamFactory());
        }
      }

      let tms = state.get("teams");
      tms = tms.push(teamFactory());

      return state.set("numberOfTeams", num).set("teams", tms);
    }
    case Actions.SET_TEAM_NAME: {
      return state.setIn(
        ["teams", action.payload.index],
        teamFactory(action.payload.name)
      );
    }
    case Actions.SET_STUDENT_COACH: {
      return state.set("isStudentCoach", action.payload.isStudentCoach);
    }
    case Actions.TEAM_MEMBER_ADD: {
      const members: List<Map<string, any>> =
        state.getIn(["teams", action.payload.teamIndex, "members"]) || List();
      return state.setIn(
        ["teams", action.payload.teamIndex, "members"],
        members.push(teamMemberFactory())
      );
    }
    case Actions.TEAM_MEMBER_EDIT: {
      const member: Map<string, any> =
        state.getIn([
          "teams",
          action.payload.teamIndex,
          "members",
          action.payload.memberIndex,
        ]) || Map();
      return state.setIn(
        [
          "teams",
          action.payload.teamIndex,
          "members",
          action.payload.memberIndex,
        ],
        member.mergeDeep(action.payload.data)
      );
    }
    case Actions.REMOVE_LAST_TEAM: {
      let teams = state.get("teams");

      //teams = teams.pop();
      return state.set("teams", teams.delete(action.payload.teamIndex));
    }
    case Actions.TEAM_MEMBER_DELETE: {
      const members: List<Map<string, any>> =
        state.getIn(["teams", action.payload.teamIndex, "members"]) || List();
      return state.setIn(
        ["teams", action.payload.teamIndex, "members"],
        members.delete(action.payload.memberIndex)
      );
    }
    case Actions.TEAM_MEMBERS_CLEAR_ALL:
      return state.set(
        "teams",
        state.get("teams").map((team) => teamFactory(team.get("name")))
      );
    case Actions.CONFIRM:
      return state.set("isCreating", true);
    case Actions.CONFIRM_SUCCESS:
      return defaultStore;
    case Actions.CONFIRM_ERROR:
      return state.merge({
        isCreating: false,
        errorMessage: action.payload.errorMessage,
      });
    default:
      return state;
  }
}

// Action creators
/**
 * Go next step in the stepper
 */
export function next(): Action {
  return action(Actions.STEP_NEXT);
}
/**
 * Go one step back in the stepper
 */
export function back(): Action {
  return action(Actions.STEP_BACK);
}
/**
 * Set contest abbreviation
 * @param {string} contestAbbr {abbr}-{year}
 */
export function setContest(contestAbbr: string): Action {
  return action(Actions.SET_CONTEST, contestAbbr);
}
/**
 * Set site id
 * @param {number} siteId Id of site
 */
export function setSite(siteId: string): Action {
  return action(Actions.SET_SITE, siteId);
}
/**
 * Set institution unit assignment id
 * @param {number} iuaId Institution unit assignment id
 */
export function setInstitution(iuaId: number): Action {
  return action(Actions.SET_INSTITUTION, iuaId);
}
/**
 * Sets number of teams to create
 * @param {number} num Number of teams to create
 */
export function setNumberOfTeams(num: number): Action {
  return action(Actions.SET_NUMBER_OF_TEAMS, num);
}
/**
 * Sets name of index-th team in the teams array
 * @param {number} index Index of a team in the store
 * @param {string} name New team name
 */
export function setTeamName(index: number, name: string): Action {
  return action(Actions.SET_TEAM_NAME, { index, name });
}
/**
 * Removes last team from the teams array

 */
export function removeTeam(teamIndex: number): Action {
  return action(Actions.REMOVE_LAST_TEAM, { teamIndex });
}
/**
 * Sets the student coach flag. Student coach participates in all teams as contestant.
 * @param {boolean} isStudentCoach New flag
 */
export function setStudentCoach(isStudentCoach: boolean): Action {
  return action(Actions.SET_STUDENT_COACH, { isStudentCoach });
}
/**
 * Adds team member for a team on index-th team in the array
 * @param {number} index Index of a team in array
 */
export function addTeamMember(teamIndex: number): Action {
  return action(Actions.TEAM_MEMBER_ADD, { teamIndex });
}
/**
 * Edits team member on index positions with new data
 * @param {number} teamIndex Index of a team in array
 * @param {number} memberIndex Index of a team member in array of team members in a team at teamIndex
 * @param {Object} data New data to merge with the store
 */
export function editTeamMember(
  teamIndex: number,
  memberIndex: number,
  data: Object
): Action {
  return action(Actions.TEAM_MEMBER_EDIT, { teamIndex, memberIndex, data });
}
/**
 * Removes team member of a team
 * @param {number} teamIndex
 * @param {number} memberIndex
 */
export function deleteTeamMember(
  teamIndex: number,
  memberIndex: number
): Action {
  return action(Actions.TEAM_MEMBER_DELETE, { teamIndex, memberIndex });
}
/**
 * Clears all team members in all teams
 */
export function clearTeamMembers(): Action {
  return action(Actions.TEAM_MEMBERS_CLEAR_ALL);
}
/**
 * Confirm registration
 */
export function confirm(): Action {
  return action(Actions.CONFIRM);
}
/**
 * Registration successfull
 */
export function confirmSuccess(): Action {
  return action(Actions.CONFIRM_SUCCESS);
}
/**
 * Error in registration
 */
export function confirmError(errorMessage: string = ""): Action {
  return action(Actions.CONFIRM_ERROR, { errorMessage });
}

// Side effects
function* goNext(): Generator<Function, void, void> {
  yield put(next());
}

function* postConfirm(action: Action) {
  const dtos: Array<TeamRegistrationDto> = yield select(
    getTeamRegistrationDtos
  );
  yield put(
    snackbar.showMessage(`Please wait, this may take a while...`, {
      duration: 3000,
    })
  );
  try {
    const payload = yield call(api.registerTeams, dtos);
    if (!payload) {
      yield put(
        snackbar.showMessage(`Error creating teams, please try again later.`)
      );
      yield put(confirmError());
    } else {
      yield put(snackbar.showMessage(`Teams successfully created!`));
      yield put(confirmSuccess());
      yield put(push("/private/teamsRegistered", payload.data));
      // const lastId = Array.isArray(payload.data) ? payload.data.pop() : null;

      // // redirect to the last team page
      // if (lastId !== null) {
      //   //yield put(push(`/private/teams/${lastId}`));

      //   yield put(push( "/private/teamsRegistered",payload.data));
      // }
    }
  } catch (err) {
    console.log(err)
    const message = errorMessage(err);
    yield put(snackbar.showErrorMessage(err));
    yield put(confirmError(message));
  }
}

function* verifySite(action: Action): Generator<Function, void, void> {
  const siteIdParsed = parseInt(action.payload, 10);
  // bad id, redirect to private
  if (isNaN(siteIdParsed)) {
    yield put(snackbar.showMessage("Site id must be a number."));
    yield put(push("/private"));
  } else {
    try {
      const payload = yield call(api.isSiteRegistrable, siteIdParsed);
      if (payload.data) {
        // if true, means it is registrable, go next in wizard
        yield put(next());
      } else {
        // else not registrable, redirect to private
        yield put(
          snackbar.showMessage("This site/contest does not allow registration.")
        );
        yield put(push("/private"));
      }
    } catch (error) {
      yield put(snackbar.showMessage("Site does not exist."));
      yield put(push("/private"));
    }
  }
}

function* clear(): Generator<Function, void, void> {
  const activeStep = yield select(getActiveStep);
  //if (activeStep === 2) yield put(clearTeamMembers());
  if (activeStep === 1) yield put(clearTeamMembers());
}

function* watchSetSite(): Generator<Function, void, void> {
  yield takeLatest(Actions.SET_SITE, verifySite);
}

function* watchSetContest(): Generator<Function, void, void> {
  yield takeLatest(Actions.SET_CONTEST, goNext);
}

function* watchConfirm(): Generator<Function, void, void> {
  yield takeLatest(Actions.CONFIRM, postConfirm);
}

function* watchBack(): Generator<Function, void, void> {
  yield takeLatest(Actions.STEP_BACK, clear);
}

export function* teamRegistrationSagas(): Generator<Function, void, void> {
  yield all([
    fork(watchSetContest),
    fork(watchSetSite),
    fork(watchConfirm),
    fork(watchBack),
  ]);
}
