// @flow
/**
 * Team selectors
 **/
import {createSelector} from "reselect";
import {fromJS,List, Map} from "immutable";

const getTeam = (state: Map<string, any>) => {
  return state.get("team");
};

const isRestrictionsLoading = createSelector(
  getTeam,
  (team: Map<string, any>) => {
    return team.getIn(["loading", "restrictions"]);
  }
);

const isTeamDtoLoading = createSelector(getTeam, (team: Map<string, any>) => {
  return team.getIn(["loading", "teamDto"]);
});

const isCreatingMember = createSelector(getTeam, (team: Map<string, any>) => {
  return team.getIn(["loading", "membercreate"]);
});

const isTeamUpdating = createSelector(getTeam, (team: Map<string, any>) => {
  return team.getIn(["loading", "teamDtoUpdate"]);
});

const isTeamMoving = createSelector(getTeam, (team: Map<string, any>) => {
  return team.getIn(["loading", "teamMoving"]);
});

const isMembersLoading = createSelector(getTeam, (team: Map<string, any>) => {
  return team.get("membersLoading");
});

const isDeleting = createSelector(getTeam, (team: Map<string, any>) => {
  return team.get("deleting");
});

const isCanceling = createSelector(getTeam, (team: Map<string, any>) => {
  return team.get("canceling");
});

const getRestrictions = createSelector(getTeam, (team: Map<string, any>) => {
  return team.get("restrictions").toJS();
});

const getGenericFields = createSelector(getTeam, (team: Map<string, any>) => {
  return team.get("genericFields");
});

const getTeamDto = createSelector(getTeam, (team: Map<string, any>) => {
  return team.get("teamDto").toJS();
});

const getSiteDetailsDto = createSelector(getTeam, (team: Map<string, any>) => {
  return team.get("siteDetailsDto").toJS();
});

const getOtherSites = createSelector(getTeam, (team: Map<string, any>) => {
  return team.get("otherSites").toJS();
});

const getCurrentSite = createSelector(getTeam, (team: Map<string, any>) => {
  const currentSite = team.getIn(["teamDto", "site"]);
  return currentSite ? currentSite.toJS() : null;
});

const getCoach = createSelector(getTeam, (team: Map<string, any>) => {
  const teamMembers: List<Map<string, any>> = team.get("teamMembers");
  if (teamMembers && teamMembers.size > 0) {
    return teamMembers.find(
      member =>
        member.get("role") === "COACH" || member.get("role") === "STUDENT_COACH"
    );
  }
  return null;
});

const getTeamMembers = createSelector(getTeam, (team: Map<string, any>) => {
  return team.get("teamMembers");
});

const isCoach = (member: Map<string, any>) => {
  return (
    member.get("role") === "COACH" || member.get("role") === "STUDENT_COACH"
  );
};

const isCocoach = (member: Map<string, any>) => {
  return member.get("role") === "COCOACH";
};

const isContestant = (member: Map<string, any>) => {
  return member.get("role") === "CONTESTANT";
};

const isGenericFieldsPosting = createSelector(
  getTeam,
  (team: Map<string, any>) => {
    return team.get("genericFieldsPosting");
  }
);

const getTeamMembersSortedForReport = createSelector(
  getTeam,
  (team: Map<string, any>) => {
    return team.get("teamMembers").sort((mem1, mem2) => {
      if (isCoach(mem1)) return -1;
      if (isCocoach(mem1) && !isCoach(mem2)) return -1;
      if (isContestant(mem1) && !isCoach(mem2) && !isCocoach(mem2)) return -1;
      if (isContestant(mem1) && isContestant(mem2)) return 0;
      return 1;
    });
  }
);

export {
  isRestrictionsLoading,
  isTeamDtoLoading,
  isTeamUpdating,
  isTeamMoving,
  isCreatingMember,
  isMembersLoading,
  getRestrictions,
  getTeamDto,
  getSiteDetailsDto,
  getTeamMembers,
  getTeamMembersSortedForReport,
  getCoach,
  getOtherSites,
  getCurrentSite,
  getGenericFields,
  isDeleting,
  isCanceling,
  isGenericFieldsPosting
};
