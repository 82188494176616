// @flow

/*
 * Security duck with action creators and reducers
 * see https://github.com/erikras/ducks-modular-redux for pattern guide
 *
 **/
import { fromJS, Map } from "immutable";
import {
  all,
  call,
  fork,
  put,
  putResolve,
  select,
  takeLatest,
} from "redux-saga/effects";

import action from "common/utils/flux";
import { showErrorMessage, showMessage } from "containers/Snackbar/duck";

import {
  fetchRoles,
  getCurrentUser,
  intercept,
  registerUser,
  updateUsername,
} from "./api.js";
import { CLIENT } from "api";
import UserRoles from "./UserRoles";

import { LOCATION_CHANGE, push } from "connected-react-router/immutable";
import * as securitySelectors from "./selectors";

//TODO move into common
import * as commonSelectors from "selectors";
import type { Action } from "common/utils/flux";
import { Auth } from "aws-amplify";
import type { LoginDto } from "routes/auth/Login/types";
import type { RegisterDto } from "routes/auth/Register/types";
import type {
  ChangePasswordDto,
  ForceChangePasswordDto,
  SendPasswordResetEmailDto,
  ResetPasswordDto,
  ConfirmUserDto,
} from "routes/auth/Login/types";
import type { LoginChallengeDto } from "./types";
import { LoginChallenge } from "./enum.js";
import type { UsernameChangeDto } from "routes/private/PersonRegistration/types";

const REGISTRATION_LOCATION = "/register";

// Action types
export const Actions = {
  INIT: "icpc/security/SECURITY_INIT",
  INIT_SUCCESS: "icpc/security/SECURITY_INIT_SUCCESS",
  INIT_ERROR: "icpc/security/SECURITY_INIT_ERROR",
  LOGIN: "icpc/security/LOGIN",
  LOGIN_SUCCESS: "icpc/security/LOGIN_SUCCESS",
  LOGIN_ERROR: "icpc/security/LOGIN_ERROR",
  LOGIN_CHALLENGE: "icpc/security/LOGIN_CHALLENGE",
  LOGIN_CHALLENGE_SUCCESS: "icpc/security/LOGIN_CHALLENGE_SUCCESS",
  REGISTER: "icpc/security/REGISTER",
  REGISTER_SUCCESS: "icpc/security/REGISTER_SUCCESS",
  REGISTER_ERROR: "icpc/security/REGISTER_ERROR",
  CONFIRM_REGISTER: "icpc/security/CONFIRM_REGISTER",
  CONFIRM_REGISTER_SUCCESS: "icpc/security/CONFIRM_REGISTER_SUCCESS",
  CONFIRM_REGISTER_ERROR: "icpc/security/CONFIRM_REGISTER_ERROR",
  FINISH_REGISTER_SUCCESS: "icpc/security/FINISH_REGISTER_SUCCESS",
  LOGOUT: "icpc/security/LOGOUT",
  LOGOUT_SUCCESS: "icpc/security/LOGOUT_SUCCESS",
  LOGOUT_ERROR: "icpc/security/LOGOUT_ERROR",
  GET_USER_ROLES: "icpc/security/GET_USER_ROLES",
  GET_USER_ROLES_SUCCESS: "icpc/security/GET_USER_ROLES_SUCCESS",
  GET_USER_ROLES_ERROR: "icpc/security/GET_USER_ROLES_ERROR",
  IMPERSONATE: "icpc/security/IMPERSONATE",
  IMPERSONATE_SUCCESS: "icpc/security/IMPERSONATE_SUCCESS",
  IMPERSONATE_ERROR: "icpc/security/IMPERSONATE_ERROR",
  CHANGE_PASSWORD: "icpc/security/CHANGE_PASSWORD",
  CHANGE_PASSWORD_SUCCESS: "icpc/security/CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_ERROR: "icpc/security/CHANGE_PASSWORD_ERROR",
  FORCE_CHANGE_PASSWORD: "icpc/security/FORCE_CHANGE_PASSWORD",
  FORCE_CHANGE_PASSWORD_SUCCESS: "icpc/security/FORCE_CHANGE_PASSWORD_SUCCESS",
  FORCE_CHANGE_PASSWORD_ERROR: "icpc/security/FORCE_CHANGE_PASSWORD_ERROR",
  FORGOT_PASSWORD: "icpc/security/FORGOT_PASSWORD",
  SEND_PASSWORD_RESET_EMAIL: "icpc/security/SEND_PASSWORD_RESET_EMAIL",
  SEND_PASSWORD_RESET_EMAIL_SUCCESS:
    "icpc/security/SEND_PASSWORD_RESET_EMAIL_SUCCESS",
  SEND_PASSWORD_RESET_EMAIL_ERROR:
    "icpc/security/SEND_PASSWORD_RESET_EMAIL_ERROR",
  RESET_PASSWORD: "icpc/security/RESET_PASSWORD",
  RESET_PASSWORD_SUCCESS: "icpc/security/RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_ERROR: "icpc/security/RESET_PASSWORD_ERROR",
  CHANGE_USERNAME: "icpc/security/CHANGE_USERNAME",
  CHANGE_USERNAME_SUCCESS: "icpc/security/CHANGE_USERNAME_SUCCESS",
  CHANGE_USERNAME_ERROR: "icpc/security/CHANGE_USERNAME_ERROR",
  CONFIRM_CHANGE_USERNAME: "icpc/security/CONFIRM_CHANGE_USERNAME",
  CONFIRM_CHANGE_USERNAME_SUCCESS:
    "icpc/security/CONFIRM_CHANGE_USERNAME_SUCCESS",
  CONFIRM_CHANGE_USERNAME_ERROR: "icpc/security/CONFIRM_CHANGE_USERNAME_ERROR",
  RESEND_SIGNUP_CODE: "icpc/security/RESEND_SIGNUP_CODE",
  RESEND_SIGNUP_CODE_SUCCESS: "icpc/security/RESEND_SIGNUP_CODE_SUCCESS",
  RESEND_SIGNUP_CODE_ERROR: "icpc/security/RESEND_SIGNUP_CODE_ERROR",
  VERIFY_USER_ATTRIBUTE: "icpc/security/VERIFY_USER_ATTRIBUTE",
  VERIFY_USER_ATTRIBUTE_SUCCESS: "icpc/security/VERIFY_USER_ATTRIBUTE_SUCCESS",
  VERIFY_USER_ATTRIBUTE_ERROR: "icpc/security/VERIFY_USER_ATTRIBUTE_ERROR",
  CONFIRM_VERIFY_USER_ATTRIBUTE: "icpc/security/CONFIRM_VERIFY_USER_ATTRIBUTE",
  CONFIRM_VERIFY_USER_ATTRIBUTE_SUCCESS:
    "icpc/security/CONFIRM_VERIFY_USER_ATTRIBUTE_SUCCESS",
  CONFIRM_VERIFY_USER_ATTRIBUTE_ERROR:
    "icpc/security/CONFIRM_VERIFY_USER_ATTRIBUTE_ERROR",
  ROLL_BACK_USER_ATTRIBUTE: "icpc/security/ROLL_BACK_USER_ATTRIBUTE",
  ROLL_BACK_USER_ATTRIBUTE_SUCCESS:
    "icpc/security/ROLL_BACK_USER_ATTRIBUTE_SUCCESS",
  ROLL_BACK_USER_ATTRIBUTE_ERROR:
    "icpc/security/ROLL_BACK_USER_ATTRIBUTE_ERROR",
};
const defaultStore = Map({
  currentUser: Map({
    loggedIn: false,
  }),
  userRoles: new UserRoles(),
  initialized: false,
  loading: false,
  error: false,
  impersonating: false,
  loginAttemptFailed: false,
});
// Reducers
export default function reducer(
  state: Map<string, any> = defaultStore,
  action: Action
) {
  switch (action.type) {
    case Actions.INIT:
      return state.merge({
        currentUser: Map({ loggedIn: false }),
        loading: true,
        error: false,
      });
    case Actions.INIT_SUCCESS:
      return state.merge({
        currentUser: fromJS(action.payload),
        loading: false,
        initialized: true,
      });
    case Actions.INIT_ERROR:
      return state.merge({
        currentUser: Map({ loggedIn: false }),
        loading: false,
      });
    case Actions.GET_USER_ROLES:
      return state;
    case Actions.GET_USER_ROLES_SUCCESS:
      return state.set("userRoles", new UserRoles(fromJS(action.payload)));
    case Actions.GET_USER_ROLES_ERROR:
      return state.set("userRoles", new UserRoles());
    case Actions.IMPERSONATE:
      return state.set("impersonating", true);
    case Actions.IMPERSONATE_SUCCESS:
      return state.set("impersonating", false);
    case Actions.IMPERSONATE_ERROR:
      return state.set("impersonating", false);
    case Actions.FINISH_REGISTER_SUCCESS:
      return state.set("userRoles", state.get("userRoles").setRegistered(true));
    case Actions.LOGIN:
      return state.merge({
        currentUser: Map({ loggedIn: false }),
        loading: true,
        error: false,
        loginChallenge: false,
        username: action.payload.username,
        loginAttemptFailed: false,
      });
    case Actions.LOGIN_SUCCESS:
      return state.merge({
        currentUser: fromJS(action.payload),
        loading: false,
        initialized: true,
        loginChallenge: false,
        loginAttemptFailed: false,
      });
    case Actions.LOGIN_ERROR:
      return state.merge({
        currentUser: Map({ loggedIn: false }),
        loading: false,
        loginAttemptFailed: true,
      });
    case Actions.LOGIN_CHALLENGE:
      return state.merge({
        loginChallenge: action.payload.loginChallenge,
        username: action.payload.username,
        awsUser: fromJS(action.payload.awsUser),
        loginChallengeInfo: fromJS(action.payload.loginChallengeInfo),
      });
    case Actions.LOGIN_CHALLENGE_SUCCESS:
      return state.merge({
        loginChallenge: null,
        loginAttemptFailed: false,
        loginChallengeInfo: {},
      });
    case Actions.LOGOUT:
      return state;
    case Actions.LOGOUT_SUCCESS:
      return state.merge({
        currentUser: fromJS(action.payload),
        loading: false,
        initialized: true,
      });
    case Actions.LOGOUT_ERROR:
      return state;
    default:
      return state;
  }
}

// Action creators
export function init(): Action {
  return action(Actions.INIT);
}

export function initSuccess(payload: any): Action {
  return action(Actions.INIT_SUCCESS, payload);
}

export function initError(err: Error): Action {
  return action(Actions.INIT_ERROR, err);
}

export function getUserRoles(): Action {
  return action(Actions.GET_USER_ROLES);
}

export function getUserRolesSuccess(payload: any): Action {
  return action(Actions.GET_USER_ROLES_SUCCESS, payload.data);
}

export function getUserRolesError(err: Error): Action {
  return action(Actions.GET_USER_ROLES_ERROR, err);
}

export function impersonate(username: string): Action {
  return action(Actions.IMPERSONATE, { username });
}

export function impersonateSuccess(payload: any): Action {
  return action(Actions.IMPERSONATE_SUCCESS, payload);
}

export function impersonateError(err: Error): Action {
  return action(Actions.IMPERSONATE_ERROR, err);
}

export function login(loginDto: LoginDto): Action {
  return action(Actions.LOGIN, loginDto);
}

export function loginSuccess(payload: any): Action {
  return action(Actions.LOGIN_SUCCESS, payload);
}

export function loginError(err: Error): Action {
  return action(Actions.LOGIN_ERROR, err);
}

export function loginChallenge(loginChallengeDto: LoginChallengeDto): Action {
  return action(Actions.LOGIN_CHALLENGE, loginChallengeDto);
}

export function loginChallengeSuccess(): Action {
  return action(Actions.LOGIN_CHALLENGE_SUCCESS);
}

export function register(registerDto: RegisterDto): Action {
  return action(Actions.REGISTER, registerDto);
}

export function registerSuccess(payload: any): Action {
  return action(Actions.REGISTER_SUCCESS, payload);
}

export function registerError(err: Error): Action {
  return action(Actions.REGISTER_ERROR, err);
}

export function confirmRegister(confirmUserDto: ConfirmUserDto): Action {
  return action(Actions.CONFIRM_REGISTER, confirmUserDto);
}

export function confirmRegisterSuccess(payload: any): Action {
  return action(Actions.CONFIRM_REGISTER_SUCCESS, payload);
}

export function confirmRegisterError(err: Error): Action {
  return action(Actions.CONFIRM_REGISTER_ERROR, err);
}

export function finishRegisterSuccess(): Action {
  return action(Actions.FINISH_REGISTER_SUCCESS);
}

export function logout(): Action {
  return action(Actions.LOGOUT);
}

export function logoutSuccess(payload: any): Action {
  return action(Actions.LOGOUT_SUCCESS, payload);
}

export function logoutError(err: Error): Action {
  return action(Actions.LOGOUT_ERROR, err);
}

export function changePassword(changePasswordDto: ChangePasswordDto): Action {
  return action(Actions.CHANGE_PASSWORD, changePasswordDto);
}

export function changePasswordSuccess(payload: any): Action {
  return action(Actions.CHANGE_PASSWORD_SUCCESS, payload);
}

export function changePasswordError(err: Error): Action {
  return action(Actions.CHANGE_PASSWORD_ERROR, err);
}

export function forceChangePassword(
  forceChangePasswordDto: ForceChangePasswordDto
): Action {
  return action(Actions.FORCE_CHANGE_PASSWORD, forceChangePasswordDto);
}

export function forceChangePasswordSuccess(payload: any): Action {
  return action(Actions.FORCE_CHANGE_PASSWORD_SUCCESS, payload);
}

export function forceChangePasswordError(err: Error): Action {
  return action(Actions.FORCE_CHANGE_PASSWORD_ERROR, err);
}

export function forgotPassword(): Action {
  return action(Actions.FORGOT_PASSWORD);
}

export function sendPasswordResetEmail(
  sendPasswordResetEmailDto: SendPasswordResetEmailDto
): Action {
  return action(Actions.SEND_PASSWORD_RESET_EMAIL, sendPasswordResetEmailDto);
}

export function sendPasswordResetEmailSuccess(payload: any): Action {
  return action(Actions.SEND_PASSWORD_RESET_EMAIL_SUCCESS, payload);
}

export function sendPasswordResetEmailError(err: Error): Action {
  return action(Actions.SEND_PASSWORD_RESET_EMAIL_ERROR, err);
}

export function resetPassword(resetPasswordDto: ResetPasswordDto): Action {
  return action(Actions.RESET_PASSWORD, resetPasswordDto);
}

export function resetPasswordSuccess(payload: any): Action {
  return action(Actions.RESET_PASSWORD_SUCCESS, payload);
}

export function resetPasswordError(err: Error): Action {
  return action(Actions.RESET_PASSWORD_ERROR, err);
}

export function changeUsername(usernameChangeDto: UsernameChangeDto): Action {
  return action(Actions.CHANGE_USERNAME, usernameChangeDto);
}

export function changeUsernameSuccess(payload: any): Action {
  return action(Actions.CHANGE_USERNAME_SUCCESS, payload);
}

export function changeUsernameError(err: Error): Action {
  return action(Actions.CHANGE_USERNAME_ERROR, err);
}

export function confirmChangeUsername(confirmUserDto: ConfirmUserDto): Action {
  return action(Actions.CONFIRM_CHANGE_USERNAME, confirmUserDto);
}

export function confirmChangeUsernameSuccess(payload: any): Action {
  return action(Actions.CONFIRM_CHANGE_USERNAME_SUCCESS, payload);
}

export function confirmChangeUsernameError(err: Error): Action {
  return action(Actions.CONFIRM_CHANGE_USERNAME_ERROR, err);
}

export function resendSignupCode(username: string): Action {
  return action(Actions.RESEND_SIGNUP_CODE, username);
}

export function resendSignupCodeSuccess(payload: any): Action {
  return action(Actions.RESEND_SIGNUP_CODE_SUCCESS, payload);
}

export function resendSignupCodeError(err: Error): Action {
  return action(Actions.RESEND_SIGNUP_CODE_ERROR, err);
}

export function verifyUserAttribute(): Action {
  return action(Actions.VERIFY_USER_ATTRIBUTE);
}

export function verifyUserAttributeSuccess(payload: any): Action {
  return action(Actions.VERIFY_USER_ATTRIBUTE_SUCCESS, payload);
}

export function verifyUserAttributeError(err: Error): Action {
  return action(Actions.VERIFY_USER_ATTRIBUTE_ERROR, err);
}

export function confirmVerifyUserAttribute(
  confirmUserDto: ConfirmUserDto
): Action {
  return action(Actions.CONFIRM_VERIFY_USER_ATTRIBUTE, confirmUserDto);
}

export function confirmVerifyUserAttributeSuccess(payload: any): Action {
  return action(Actions.CONFIRM_VERIFY_USER_ATTRIBUTE_SUCCESS, payload);
}

export function confirmVerifyUserAttributeError(err: Error): Action {
  return action(Actions.CONFIRM_VERIFY_USER_ATTRIBUTE_ERROR, err);
}

export function rollBackUserAttribute(
  usernameChangeDto: UsernameChangeDto
): Action {
  return action(Actions.ROLL_BACK_USER_ATTRIBUTE, usernameChangeDto);
}

export function rollBackUserAttributeSuccess(payload: any): Action {
  return action(Actions.ROLL_BACK_USER_ATTRIBUTE_SUCCESS, payload);
}

export function rollBackUserAttributeError(err: Error): Action {
  return action(Actions.ROLL_BACK_USER_ATTRIBUTE_ERROR, err);
}

// Sagas
function* initSaga(action: Action): Generator<Function, void, void> {
  try {
    const user = yield Auth.currentAuthenticatedUser();
    yield call(intercept);

    try {
      // Resolve the Cognito user with the database
      const icpcUser = yield call(getCurrentUser);
      if (icpcUser && icpcUser.data) {
        const currentUser = {
          loggedIn: icpcUser.data !== null,
          email: icpcUser.data.userName,
          fullName: `${icpcUser.data.firstName} ${icpcUser.data.lastName}`,
          firstName: icpcUser.data.firstName,
          lastName: icpcUser.data.lastName,
          uid: icpcUser.data.id,
        };
        yield put(initSuccess(currentUser));
        const isLoggedIn = yield select(securitySelectors.isLoggedIn);
        if (isLoggedIn) {
          yield put(getUserRoles());
        }
      } else {
        // No user found - send to finish registration
        let username = yield select(securitySelectors.getUsername);
        if (username === undefined || username === null) {
          username = user.attributes.email;
        }
        yield put(
          loginChallenge({
            awsUser: null,
            username: username,
            loginChallenge: LoginChallenge.FINISH_REGISTRATION,
          })
        );
        yield put(push("/login"));
      }
    } catch (err) {
      // Check if the email isn't verified. If not, reverify it. Cache bypass required if user has changed username.
      const user = yield Auth.currentAuthenticatedUser({ bypassCache: true });
      if (user.attributes.email && !user.attributes.email_verified) {
        yield put(verifyUserAttribute());
      } else {
        yield put(initError(err));
        yield put(showErrorMessage(err));
      }
    }
  } catch (err) {
    // User is not logged in
    const currentUser = {
      loggedIn: false,
    };
    yield put(initSuccess(currentUser));
  }
}

function* loginSaga(action: Action): Generator<Function, void, void> {
  try {
    const awsUser = yield Auth.signIn(
      action.payload.username,
      action.payload.password
    );
    // If there's a challenge, we need to handle it
    if (awsUser.challengeName) {
      yield put(
        loginChallenge({
          awsUser: awsUser,
          username: action.payload.username,
          loginChallenge: LoginChallenge.CHANGE_PASSWORD,
        })
      );
    } else {
      yield put(loginSuccess());
      yield putResolve(init());
      if (securitySelectors.isLoggedIn) {
        yield put(push("/private"));
      }
    }
  } catch (error) {
    if (error.code === "UserNotConfirmedException") {
      yield put(
        loginChallenge({
          awsUser: null,
          username: action.payload.username,
          loginChallenge: LoginChallenge.CONFIRM_REGISTRATION,
        })
      );
    } else {
      yield put(showErrorMessage(error));
      yield put(loginError(error));
    }
  }
}

function* logoutSaga(action: Action): Generator<Function, void, void> {
  try {
    const isLoggedIn = yield select(securitySelectors.isLoggedIn);
    if (isLoggedIn) {
      yield Auth.signOut();
      yield put(logoutSuccess(Map({ loggedIn: false })));
      yield put(push(""));
      // Refresh
      window.location.reload();
    }
  } catch (err) {
    yield put(showMessage("Cannot log out right now. Please try again later."));
  }
}

function* registerSaga(action: Action): Generator<Function, void, void> {
  try {
    if (action.payload.password !== action.payload.confirmPassword) {
      yield put(showErrorMessage(new Error("Passwords do not match")));
    } else {
      const username = action.payload.username;
      const password = action.payload.password;
      const user = yield Auth.signUp({ username, password });
      if (user) {
        yield put(registerSuccess());
        yield put(
          loginChallenge({
            awsUser: null,
            username: username,
            loginChallenge: LoginChallenge.CONFIRM_REGISTRATION,
          })
        );
      } else {
        yield put(registerError(new Error("Unable to register")));
      }
    }
  } catch (err) {
    yield put(registerError(err));
    yield put(showErrorMessage(err));
  }
}

function* confirmRegisterSaga(action: Action): Generator<Function, void, void> {
  try {
    yield Auth.confirmSignUp(action.payload.username, action.payload.code);
    yield put(confirmRegisterSuccess());
    // Erase the existing challenge
    yield put(loginChallengeSuccess());
  } catch (err) {
    yield put(confirmRegisterError(err));
    yield put(showErrorMessage(err));
  }
}

function* rolesSaga(action: Action): Generator<Function, void, void> {
  try {
    const payload = yield call(fetchRoles);
    yield put(getUserRolesSuccess(payload));
    if (!payload.data.registered) {
      yield put(push(REGISTRATION_LOCATION));
    }
  } catch (err) {
    yield put(getUserRolesError(err));
  }
}

function* impersonateSaga(action: Action): Generator<Function, void, void> {
  try {
    const userRoles = yield select(securitySelectors.getUserRoles);
    const userToImpersonate = action.payload.username;
    const isAdmin = userRoles.isAdmin();
    if (isAdmin) {
      yield Auth.signOut();
      const user = yield Auth.signIn(userToImpersonate);
      yield Auth.sendCustomChallengeAnswer(user, "true");
      yield put(impersonateSuccess());
      // refresh current window
      window.location.reload();
    } else {
      yield put(impersonateError(new Error("Not authorized to impersonate")));
      yield put(
        showErrorMessage(new Error("You are not authorized to impersonate."))
      );
    }
  } catch (err) {
    yield put(impersonateError(err));
    yield put(showErrorMessage(err));
  }
}

/**
 * Redurects not registered user into registration form (invokes push action creator)
 */
function* redirectNotRegistered(
  action: Action
): Generator<Function, void, void> {
  // use selector to select stuff from store
  const isRegistered = yield select(securitySelectors.isRegistered);
  const currentLocation = yield select(commonSelectors.getCurrentRoute);
  if (!isRegistered && currentLocation !== REGISTRATION_LOCATION) {
    yield put(push(REGISTRATION_LOCATION));
    yield put(showMessage("You need to finish your registration"));
  }
}

function* changePasswordSaga(action: Action): Generator<Function, void, void> {
  try {
    const awsUser = yield Auth.currentAuthenticatedUser();
    yield Auth.changePassword(
      awsUser,
      action.payload.oldPassword,
      action.payload.newPassword
    );
    yield put(showMessage("Changed password successfully!"));
    yield put(changePasswordSuccess());
  } catch (err) {
    yield put(showErrorMessage(err));
    yield put(changePasswordError(err));
  }
}

function* forceChangePasswordSaga(
  action: Action
): Generator<Function, void, void> {
  try {
    const user = yield select(securitySelectors.getAwsUser);
    yield Auth.completeNewPassword(user, action.payload.newPassword);
    yield put(showMessage("Changed password successfully!"));
    yield put(forceChangePasswordSuccess());
    yield put(init());
    if (securitySelectors.isLoggedIn) {
      yield put(push("/private"));
    }
  } catch (err) {
    yield put(showErrorMessage(err));
    yield put(forceChangePasswordError(err));
  }
}

function* forgotPasswordSaga(action: Action): Generator<Function, void, void> {
  try {
    yield put(
      loginChallenge({
        awsUser: null,
        username: "",
        loginChallenge: LoginChallenge.FORGOT_PASSWORD,
      })
    );
  } catch (err) {
    yield put(showErrorMessage(err));
  }
}

function* sendPasswordResetEmailSaga(
  action: Action
): Generator<Function, void, void> {
  try {
    yield Auth.forgotPassword(action.payload.username);
    yield put(
      loginChallenge({
        awsUser: null,
        username: action.payload.username,
        loginChallenge: LoginChallenge.RESET_PASSWORD,
      })
    );
    yield put(showMessage("Sent password reset email!"));
    yield put(sendPasswordResetEmailSuccess());
  } catch (err) {
    // TODO add code to create Cognito user
    yield put(showErrorMessage(err));
    yield put(sendPasswordResetEmailError(err));
  }
}

function* resetPasswordSaga(action: Action): Generator<Function, void, void> {
  if (action.payload.newPassword !== action.payload.confirmNewPassword) {
    yield put(showErrorMessage(new Error("Passwords must match.")));
  } else {
    try {
      yield Auth.forgotPasswordSubmit(
        action.payload.username,
        action.payload.resetCode,
        action.payload.newPassword
      );
      yield put(
        showMessage("Successfully reset password, redirecting to login...")
      );
      yield put(resetPasswordSuccess());
      yield put(loginChallengeSuccess());
      yield put(push("/login"));
    } catch (err) {
      yield put(showErrorMessage(err));
      yield put(resetPasswordError(err));
    }
  }
}

/**
 * This is the normal flow for changing usernames.
 * @param {*} action
 */
function* changeUsernameSaga(action: Action): Generator<Function, void, void> {
  const oldUsername = action.payload.oldUsername;
  const newUsername = action.payload.newUsername;
  const personId = action.payload.personId;
  try {
    const currentUser = yield Auth.currentAuthenticatedUser();
    yield Auth.updateUserAttributes(currentUser, { email: newUsername });
    yield put(changeUsernameSuccess());
    yield put(
      loginChallenge({
        awsUser: currentUser,
        username: newUsername,
        loginChallenge: LoginChallenge.CONFIRM_CHANGE_USERNAME,
        loginChallengeInfo: {
          personId: personId,
          oldUsername: oldUsername,
          newUsername: newUsername,
        },
      })
    );
  } catch (err) {
    yield put(changeUsernameError(err));
    yield put(showErrorMessage(err));
  }
}

function* confirmChangeUsernameSaga(
  action: Action
): Generator<Function, void, void> {
  const code = action.payload.code;
  const username = action.payload.username;
  const usernameChangeInfo = yield select(
    securitySelectors.getLoginChallengeInfo
  );
  if (!usernameChangeInfo) {
    const err = new Error(
      "Unexpected error occured when updating username. Please refresh the page and try again."
    );
    yield put(confirmChangeUsernameError(err));
    yield put(showErrorMessage(err));
  }
  try {
    yield Auth.verifyCurrentUserAttributeSubmit("email", code);
    // Get updated info
    const user = yield Auth.currentAuthenticatedUser({ bypassCache: true });
    yield call(intercept);
    try {
      yield call(updateUsername, usernameChangeInfo.get("newUsername"));
      yield put(showMessage(`Updated username to ${username}.`));
      yield put(
        confirmChangeUsernameSuccess(`Updated username to ${username}`)
      );
      yield put(loginChallengeSuccess());
      window.location.reload();
    } catch (err) {
      try {
        yield put(rollBackUserAttribute(usernameChangeInfo));
        yield put(confirmChangeUsernameError(err));
        yield put(showErrorMessage(err));
      } catch (err) {
        yield put(confirmChangeUsernameError(err));
        yield put(showErrorMessage(err));
      }
    }
  } catch (err) {
    yield put(confirmChangeUsernameError(err));
    yield put(showErrorMessage(err));
  }
}

function* resendSignupCodeSaga(
  action: Action
): Generator<Function, void, void> {
  try {
    const username = action.payload;
    yield Auth.resendSignUp(username);
    yield put(resendSignupCodeSuccess(username));
    yield put(showMessage("Sent new code!"));
  } catch (err) {
    yield put(resendSignupCodeError(err));
    yield put(showErrorMessage(err));
  }
}

/**
 * This flow is only for users whose usernames are not properly updated. This could be
 * due to not verifying emails, refreshing the page too early, using an existing username
 * without getting caught, etc.
 */
function* verifyUserAttributeSaga(
  action: Action
): Generator<Function, void, void> {
  try {
    yield Auth.verifyCurrentUserAttribute("email");
    // Load the data for the username confirmation
    const user = yield Auth.currentAuthenticatedUser();
    yield put(verifyUserAttributeSuccess());
    yield put(
      showMessage(`Sent verification email to ${user.attributes.email}!`)
    );
    yield put(
      loginChallenge({
        awsUser: user,
        username: user.attributes.email,
        loginChallenge: LoginChallenge.CONFIRM_CHANGE_USERNAME,
      })
    );
    yield put(push("/login"));
  } catch (err) {
    yield put(verifyUserAttributeError(err));
    yield put(showErrorMessage(err));
  }
}

function* confirmVerifyUserAttributeSaga(
  action: Action
): Generator<Function, void, void> {
  try {
    yield Auth.verifyCurrentUserAttributeSubmit("email", action.payload.code);
    const user = yield Auth.currentAuthenticatedUser({ bypassCache: true });
    if (user.attributes.email_verified === true) {
      // Initialize the user ID
      yield call(intercept);
      const icpcUser = yield call(getCurrentUser);
      try {
        const username = action.payload.username;
        yield call(updateUsername, username);
        // For some reason, page gets stuck here
        yield put(
          showMessage(
            `Updated username to ${username}. Please refresh the page to continue.`
          )
        );
        yield put(
          confirmChangeUsernameSuccess(`Updated username to ${username}`)
        );
        yield put(loginChallengeSuccess());
        yield put(confirmVerifyUserAttributeSuccess());
        yield put(push("/private"));
      } catch (err) {
        try {
          yield put(
            rollBackUserAttribute({
              personId: icpcUser.data.id,
              oldUsername: action.payload.username,
              newUsername: action.payload.username,
            })
          );
          yield put(confirmChangeUsernameError(err));
          yield put(showErrorMessage(err));
        } catch (err) {
          yield put(confirmChangeUsernameError(err));
          yield put(showErrorMessage(err));
        }
      }
    } else {
      const err = new Error(
        "Error while validating email. Please try again later."
      );
      yield put(confirmVerifyUserAttributeError(err));
      yield put(showErrorMessage(err));
    }
  } catch (err) {
    yield put(confirmVerifyUserAttributeError(err));
    yield put(showErrorMessage(err));
  }
}

function* rollBackUserAttributeSaga(
  action: Action
): Generator<Function, void, void> {
  try {
    const currentUser = yield Auth.currentAuthenticatedUser();
    yield Auth.updateUserAttributes(currentUser, {
      email: action.payload.oldUsername,
      email_verified: true,
    });
  } catch (err) {
    yield put(rollBackUserAttributeError(err));
    yield put(showErrorMessage(err));
  }
}

function* watchInit(): Generator<Function, void, void> {
  yield takeLatest(Actions.INIT, initSaga);
}

function* watchLogin(): Generator<Function, void, void> {
  yield takeLatest(Actions.LOGIN, loginSaga);
}

function* watchRegister(): Generator<Function, void, void> {
  yield takeLatest(Actions.REGISTER, registerSaga);
}

function* watchConfirmRegister(): Generator<Function, void, void> {
  yield takeLatest(Actions.CONFIRM_REGISTER, confirmRegisterSaga);
}

function* watchLogout(): Generator<Function, void, void> {
  yield takeLatest(Actions.LOGOUT, logoutSaga);
}

function* watchGetRoles(): Generator<Function, void, void> {
  yield takeLatest(Actions.GET_USER_ROLES, rolesSaga);
}

/**
 * We need to watch location change and call redirectNodRegistered function every time
 */
function* watchLocationChange(): Generator<Function, void, void> {
  yield takeLatest(LOCATION_CHANGE, redirectNotRegistered);
}

function* watchImpersonate(): Generator<Function, void, void> {
  yield takeLatest(Actions.IMPERSONATE, impersonateSaga);
}

function* watchChangePassword(): Generator<Function, void, void> {
  yield takeLatest(Actions.CHANGE_PASSWORD, changePasswordSaga);
}

function* watchForceChangePassword(): Generator<Function, void, void> {
  yield takeLatest(Actions.FORCE_CHANGE_PASSWORD, forceChangePasswordSaga);
}

function* watchForgotPassword(): Generator<Function, void, void> {
  yield takeLatest(Actions.FORGOT_PASSWORD, forgotPasswordSaga);
}

function* watchSendPasswordResetEmail(): Generator<Function, void, void> {
  yield takeLatest(
    Actions.SEND_PASSWORD_RESET_EMAIL,
    sendPasswordResetEmailSaga
  );
}

function* watchResetPassword(): Generator<Function, void, void> {
  yield takeLatest(Actions.RESET_PASSWORD, resetPasswordSaga);
}

function* watchChangeUsername(): Generator<Function, void, void> {
  yield takeLatest(Actions.CHANGE_USERNAME, changeUsernameSaga);
}

function* watchConfirmChangeUsername(): Generator<Function, void, void> {
  yield takeLatest(Actions.CONFIRM_CHANGE_USERNAME, confirmChangeUsernameSaga);
}

function* watchResendSignupCode(): Generator<Function, void, void> {
  yield takeLatest(Actions.RESEND_SIGNUP_CODE, resendSignupCodeSaga);
}

function* watchVerifyUserAttribute(): Generator<Function, void, void> {
  yield takeLatest(Actions.VERIFY_USER_ATTRIBUTE, verifyUserAttributeSaga);
}

function* watchConfirmVerifyUserAttribute(): Generator<Function, void, void> {
  yield takeLatest(
    Actions.CONFIRM_VERIFY_USER_ATTRIBUTE,
    confirmVerifyUserAttributeSaga
  );
}

function* watchRollBackUserAttribute(): Generator<Function, void, void> {
  yield takeLatest(Actions.ROLL_BACK_USER_ATTRIBUTE, rollBackUserAttributeSaga);
}

export function* securitySagas(): Generator<Function, void, void> {
  yield all([
    fork(watchInit),
    fork(watchLogin),
    fork(watchRegister),
    fork(watchConfirmRegister),
    fork(watchLogout),
    fork(watchGetRoles),
    fork(watchLocationChange),
    fork(watchImpersonate),
    fork(watchChangePassword),
    fork(watchForceChangePassword),
    fork(watchForgotPassword),
    fork(watchSendPasswordResetEmail),
    fork(watchResetPassword),
    fork(watchChangeUsername),
    fork(watchConfirmChangeUsername),
    fork(watchResendSignupCode),
    fork(watchVerifyUserAttribute),
    fork(watchConfirmVerifyUserAttribute),
    fork(watchRollBackUserAttribute),
  ]);
}
