// @flow

/*
 * Site registration duck with action creators and reducers
 * see https://github.com/erikras/ducks-modular-redux for pattern guide
 *
 **/
import {fromJS, Map} from "immutable";
import {all, call, fork, put, select, takeLatest} from "redux-saga/effects";
import * as selectors from "./selectors";
import action from "common/utils/flux";
import * as snackbar from "containers/Snackbar/duck";
import {replaceAll} from "containers/PrivateApp/duck";
import * as api from "./api";
import {buildSiteMenu} from "./contextMenu";
import {push} from "connected-react-router/immutable";
import download from "common/utils/download";
import type { Action } from "common/utils/flux";

// Action types
export const Actions = {
  SITE_GET_DTO: "icpc/site/GET_DTO",
  SITE_GET_DTO_SUCCESS: "icpc/site/GET_DTO_SUCCESS",
  SITE_GET_DTO_ERROR: "icpc/site/GET_DTO_ERROR",
  SITE_GET_BREADCRUMBS: "icpc/site/GET_BREADCRUMBS",
  SITE_GET_BREADCRUMBS_SUCCESS: "icpc/site/GET_BREADCRUMBS_SUCCESS",
  SITE_GET_BREADCRUMBS_ERROR: "icpc/site/GET_BREADCRUMBS_ERROR",
  SITE_GET_OTHER_SITES_SUCCESS: "icpc/site/GET_OTHER_SITES_SUCCESS",
  SITE_GET_OTHER_SITES_ERROR: "icpc/site/GET_OTHER_SITES_ERROR",
  SITE_UPDATE_CURRENT_SITE: "icpc/site/UPDATE_CURRENT_SITE",
  SITE_EXPORT_PC2: "icpc/site/EXPORT_PC2",
  SITE_EXPORT_PRINT_DATA: "icpc/site/EXPORT_PRINT_DATA"
};

const defaultStore = Map({
  loading: false,
  error: false
});

// Reducers
export default function reducer(
  state: Map<string, any> = defaultStore,
  action: Action
) {
  switch (action.type) {
    case Actions.SITE_GET_DTO:
      return state.set("loading", true);
    case Actions.SITE_GET_DTO_SUCCESS:
      return state.merge({
        loading: false,
        error: false,
        currentSite: fromJS(action.payload)
      });
    case Actions.SITE_GET_DTO_ERROR:
      return state.merge({ loading: false, error: true });
    case Actions.SITE_GET_BREADCRUMBS_SUCCESS:
      return state.set("breadcrumbs", fromJS(action.payload));
    case Actions.SITE_GET_OTHER_SITES_SUCCESS:
      return state.set("otherSites", fromJS(action.payload));
    case Actions.SITE_UPDATE_CURRENT_SITE: {
      const currentSite = state.get("currentSite");
      return state.set("currentSite", currentSite.merge(action.payload));
    }
    default:
      return state;
  }
}

// Action creators
/**
 * Updates current site Map - merges it with value object.
 * You need to make sure that object keys are identical to those in currentSite Map
 * @param {Object} value
 */
export function updateCurrentSite(value: Object): Action {
  return action(Actions.SITE_UPDATE_CURRENT_SITE, value);
}
export function fetchSiteDto(siteId: number): Action {
  return action(Actions.SITE_GET_DTO, siteId);
}
function fetchSiteDtoSuccess(payload: Object): Action {
  return action(Actions.SITE_GET_DTO_SUCCESS, payload);
}
function fetchSiteDtoError(err: Error): Action {
  return action(Actions.SITE_GET_DTO_ERROR, err);
}
export function fetchBreadcrumbs(siteId: number): Action {
  return action(Actions.SITE_GET_BREADCRUMBS, siteId);
}
function fetchBreadcrumbsSuccess(payload: Object): Action {
  return action(Actions.SITE_GET_BREADCRUMBS_SUCCESS, payload);
}
function fetchBreadcrumbsError(err: Error): Action {
  return action(Actions.SITE_GET_BREADCRUMBS_ERROR, err);
}
function fetchOtherSitesSuccess(payload: Object): Action {
  return action(Actions.SITE_GET_OTHER_SITES_SUCCESS, payload);
}
function fetchOtherSitesError(err: Error): Action {
  return action(Actions.SITE_GET_OTHER_SITES_ERROR, err);
}
export function exportPC2(siteId: number): Action {
  return action(Actions.SITE_EXPORT_PC2, { siteId });
}
export function exportPrintData(siteId: number): Action {
  return action(Actions.SITE_EXPORT_PRINT_DATA, { siteId });
}
// Side effects
function* fetchSiteDtoAsync(action: Action): Generator<Function, void, void> {
  try {
    const payload = yield call(api.fetchSiteDto, action.payload);
    if (!payload) {
      const err = new Error("Received no site data");
      yield put(snackbar.showErrorMessage(err));
      yield put(fetchSiteDtoError(err));
    } else {
      yield put(fetchSiteDtoSuccess(payload.data));
    }
  } catch (err) {
    yield put(snackbar.showErrorMessage(err));
    yield put(push(`/private/dashboard`));
    yield put(fetchSiteDtoError(new Error("Received no data")));
  }
}

function* fetchSiteBreadcrumbsAsync(
  action: Action
): Generator<Function, void, void> {
  try {
    const payload = yield call(api.fetchBreadcrumbs, action.payload);
    if (!payload) {
      const err = new Error("Received no site data");
      yield put(snackbar.showErrorMessage(err));
      yield put(fetchBreadcrumbsError(err));
    } else {
      yield put(fetchBreadcrumbsSuccess(payload.data));
    }
  } catch (err) {
    yield put(snackbar.showErrorMessage(err));
    yield put(fetchBreadcrumbsError(new Error("Received no data")));
  }
}

function* fetchOtherSitesAsync(
  action: Action
): Generator<Function, void, void> {
  const contestId = action.payload.contestId;
  if (contestId)
    try {
      const payload = yield call(api.fetchSitesForContest, contestId);
      if (!payload) {
        const err = new Error("Received no site data");
        yield put(fetchOtherSitesError(err));
      } else {
        yield put(fetchOtherSitesSuccess(payload.data));
        // update menu with new site id and other sites
        const currentSite = yield select(selectors.getCurrentSite);
        const otherSitesMenu = yield select(selectors.getOtherSitesMenu);
        if (currentSite) {
          const menu = buildSiteMenu(currentSite.siteId);
          menu.push(otherSitesMenu);
          yield put(replaceAll(menu));
        }
      }
    } catch (err) {
      yield put(fetchOtherSitesError(new Error("Received no data")));
    }
}

function* exportPC2Async(action: Action): Generator<Function, void, void> {
  const siteId = action.payload.siteId;
  if (siteId) {
    try {
      const payload = yield call(api.exportPC2, siteId);
      download(payload.data);
    } catch (err) {
      yield put(
        snackbar.showMessage("Failed to export PC2", { variant: "error" })
      );
    }
  }
}

function* exportPrintDataAsync(
  action: Action
): Generator<Function, void, void> {
  const siteId = action.payload.siteId;
  if (siteId) {
    try {
      const payload = yield call(api.exportPrintData, siteId);
      download(payload.data);
    } catch (err) {
      yield put(
        snackbar.showMessage("Failed to export print data", {
          variant: "error"
        })
      );
    }
  }
}

function* watchFetch(): Generator<Function, void, void> {
  yield takeLatest(Actions.SITE_GET_DTO, fetchSiteDtoAsync);
}

function* watchFetchBreadcrumbs(): Generator<Function, void, void> {
  yield takeLatest(Actions.SITE_GET_BREADCRUMBS, fetchSiteBreadcrumbsAsync);
}

// when we're done fetching initial site info, fetch other sites for contest menu
function* watchFetchSuccess(): Generator<Function, void, void> {
  yield takeLatest(Actions.SITE_GET_DTO_SUCCESS, fetchOtherSitesAsync);
}

function* watchExportPC2(): Generator<Function, void, void> {
  yield takeLatest(Actions.SITE_EXPORT_PC2, exportPC2Async);
}

function* watchExportPrintData(): Generator<Function, void, void> {
  yield takeLatest(Actions.SITE_EXPORT_PRINT_DATA, exportPrintDataAsync);
}

export function* siteSagas(): Generator<Function, void, void> {
  yield all([
    fork(watchFetch),
    fork(watchFetchBreadcrumbs),
    fork(watchFetchSuccess),
    fork(watchExportPC2),
    fork(watchExportPrintData)
  ]);
}
