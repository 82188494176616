// @flow
import {Map} from "immutable";
import {createSelector} from "reselect";

/**
 * For router to redux-router conversion, don't use this
 */
const selectLocationState = () => {
  let prevRoutingState;
  let prevRoutingStateJS;

  return (state: Map<string, any>) => {
    const routingState = state.get("route"); // or state.route
    if (!routingState.equals(prevRoutingState)) {
      prevRoutingState = routingState;
      prevRoutingStateJS = routingState.toJS();
    }

    return prevRoutingStateJS;
  };
};

const getRouter = (state: Map<string, any>) => {
  return state.get("router");
};

const getLocation = createSelector(
  getRouter,
  (route: Map<string, any>) => {
    if (!route) return null;
    return route.get("location");
  }
);

const getCurrentRoute = createSelector(
  getLocation,
  (location: Map<string, any>) => {
    if (!location) return null;
    return location.get("pathname");
  }
);

export {
  selectLocationState,
  getRouter,
  getLocation,
  getCurrentRoute
};
