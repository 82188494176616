// @flow

import api from "api";
import * as resources from "resources";
import Auth from "@aws-amplify/auth";
import type { RegisterDto } from "routes/auth/Register/types";

/**
 * We use this function to intercept all secure calls in the private app.
 * We add bearer token into our headers here.
 */
export function intercept() {
  api.interceptors.request.use((config) => {
    return new Promise((resolve, reject) => {
      Auth.currentSession()
        .then((user) => {
          config.headers = {
            ...config.headers,
            ...{ Authorization: "Bearer " + user.idToken.jwtToken },
          };
          resolve(config);
        })
        .catch((err) => {
          reject("Not currently logged in: " + err);
        });
    });
  });
}

// Get the ICPC user associated with the Cognito user
export const getCurrentUser = () => {
  return api.get(resources.DataGET.PERSON_BASIC_INFO);
};

export const registerUser = (dto: RegisterDto) => {
  return api.post(resources.DataPOST.PERSON_REGISTER, dto);
};

export const fetchRoles = () => {
  return api.get(resources.DataGET.USER_ROLES);
};

export const updateUsername = (newUsername: string) => {
  return api.post(resources.DataPOST.PERSON_USERNAME_CHANGE(newUsername));
};
