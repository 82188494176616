// @flow

import {all, fork} from "redux-saga/effects";
// component Sagas
import {globalsSagas} from "common/globals/duck";
import {securitySagas} from "containers/Security/duck";
import {aspectFacesSagas} from "containers/AspectFaces/duck";
import {teamRegistrationSagas} from "routes/private/TeamRegistration/duck";
import {teamSagas} from "routes/private/Team/duck";
import {personRegistrationSagas} from "routes/private/PersonRegistration/duck";
import {siteSagas} from "routes/private/Site/duck";
import {helpSagas} from "routes/private/Help/duck";
import {eligibilityModifierSagas} from "containers/EligibilityModifier/duck";
import {xWikiSagas} from "routes/public/WikiPage/duck";
import {certificateSagas} from "routes/private/ContestApp/PrintCenter/Certificate/duck";

export default function* rootSaga(): Generator<Array<Function>, void, void> {
  yield all([
    fork(globalsSagas),
    fork(securitySagas),
    fork(aspectFacesSagas),
    fork(teamRegistrationSagas),
    fork(teamSagas),
    fork(personRegistrationSagas),
    fork(siteSagas),
    fork(helpSagas),
    fork(eligibilityModifierSagas),
    fork(xWikiSagas),
    fork(certificateSagas),
  ]);
}
